import * as React from "react";
import { Paper, Card, CardContent, CardActions, Button, Typography, Tab, Tabs } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DeleteOutline } from "@mui/icons-material";
import { useBasket, withBasketData } from "../basket/basketContext";
import { BasketData, BasketItem } from "../basket/basketData";
import { CountrySelect, CountrySelectMaterial, LocaleSelectMaterial } from "../components/countySelect";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Chip from "@mui/material/Chip";
import InputLabel from "@mui/material/InputLabel";

// import { Omit } from '@mui/material';
import {
  Datagrid,
  EditButton,
  DeleteWithUndoButton,
  Sidebar,
  TextField,
  Title,
  ImageField,
  FunctionField,
} from "react-admin";
import { useRefresh } from "ra-core";
import "../css/cart.css";
import {
  FormControlLabel,
  Box,
  IconButton,
  CardMedia,
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
  Checkbox,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { Edit } from "@mui/icons-material";
import Currency from "../components/currency";

import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
// import { CustomButton } from "../components/customButton";
import { useProducts } from "../hooks/useProducts";
import { useNavigate, Link } from "react-router-dom";
import { useCart } from "../hooks/useCheckout";
import { useCoupon } from "../hooks/useCoupon";
import { useStateMachine } from "little-state-machine";
import { clearStateAction, updateAction, updateDataAction } from "../globalState";
import { useBundles } from "../hooks/useBundles";
import CartList from "../components/cartList";
import RedditTextField from "../components/redditTextfield";
import { processENV, __DEV__, language_codes } from "../api";
import getTranslationJson from "../components/getTranslationJson";
import { useLocales } from "../hooks/useLocales";
import { gql } from "graphql-request";

const sort = { field: "name", order: "DESC" };

export const basketItemsToCartItems = (basketItems: BasketItem[]): GQL.LineItemInput[] => {
  let items: any = basketItems;
  items.map((item: any) => {
    for (var k in item) {
      if (k === "price_id") {
        item["price"] = item[k];
      }
      if (k !== "price" && k !== "quantity") {
        delete item[k];
      }
    }
    // item["taxRates"] = ["txr_1L0USBJAXUm7Q2VgZpDZ5hfY"];
    return item;
  });
  return items;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function Basket() {
  const theme = useTheme();
  const { actions, state } = useStateMachine({ updateDataAction, clearStateAction });
  const [isInitiallyFetched, setIsInitiallyFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cart, setCart] = useState<GQL.Cart>({});
  const [selectedCountry, setSelectedCountry] = useState<string>("GB");
  const [couponInput, setCouponInput] = useState("");
  const basket = useBasket();
  const mutationCart = useCart();
  const mutationCouponCheck = useCoupon();
  let navigate = useNavigate();
  const [translation, setTranslation] = useState<GQL_AEM.CheckoutTranslationModels | null>(null);

  const queryLocales = useLocales();
  const products = useProducts();
  const bundles = useBundles();
  const [tabValue, setTabValue] = React.useState(0);
  const [tabValue2, setTabValue2] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const handleTabChange2 = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue2(newValue);
  };

  const [currencyCart, setCurrencyCart] = React.useState("GBP");

  const handleCurrencyCartChange = (event: React.MouseEvent<HTMLElement>, newCurrencyCart: string) => {
    if (newCurrencyCart !== null) {
      setCurrencyCart(newCurrencyCart);
    }
  };
  const [languageCart, setLanguageCart] = React.useState("EN");
  const handleLanguageCartChange = (event: React.MouseEvent<HTMLElement>, newLanguageCart: string) => {
    if (newLanguageCart !== null) {
      setLanguageCart(newLanguageCart);
    }
  };

  const [locale, setLocale] = React.useState<string>("");
  const handleLocaleChange = (newLocale: string) => {
    if (newLocale !== null && newLocale !== undefined) {
      let locale = JSON.parse(newLocale) as GQL.LocaleModel;
      setLanguageCart(locale.defaultLanguage.toUpperCase());
      setSelectedCountry(locale.country);
      setCurrencyCart(locale.defaultCurrency);
      setLocale(newLocale);
    }
  };

  const [emailTypeCart, setEmailTypeCart] = React.useState("");

  const handleEmailTypeCartChange = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
    if (newValue !== null) {
      setEmailTypeCart(newValue);
    }
  };

  useEffect(() => {
    if (isInitiallyFetched) {
      // localStorage.setItem("cart", JSON.stringify(basket.items));
      // console.log("BASKET", basket.items);

      if (__DEV__) {
        console.log("STATE", state);
      }
      if (languageCart) {
        setTranslation(getTranslationJson(languageCart));
      }
      let cartItemListSimple =
        cart && cart.lineItems
          ? cart.lineItems.map((lineItem) => {
              return { lineId: lineItem?.lineId, quantity: lineItem?.quantity };
            })
          : [];
      let cartData: GQL.MutationCartArgs = {
        currency: currencyCart,
        // lineItems: basketItemsToCartItems(basket.items),
        // lineItems: state.data.cartItems,
        lineItems: cart.lineItems ? cartItemListSimple : [],
        cartId: state.data.cartId,
        country: selectedCountry,
        language: languageCart,
        emailType: emailTypeCart,
      };
      mutationCart.mutate(cartData, {
        onSuccess: (data) => {
          actions.updateDataAction({ cartId: data.cartId });
          setCart(data);
        },
      });

      setIsLoading(false);
    } else {
      // let prev_items = JSON.parse(localStorage.getItem("cart") || "[]") || [];
      // basket.setInitialData([]);
      actions.clearStateAction({});
      setIsInitiallyFetched(true);
    }
  }, [isInitiallyFetched, selectedCountry, languageCart, currencyCart]);

  const onCouponInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setCouponInput(newValue);
  };
  const setCouponBtn = () => {
    if (couponInput === "") {
      let cartData: GQL.MutationCartArgs = {
        cartId: state.data.cartId,
        couponCode: couponInput,
        country: selectedCountry,
        language: languageCart,
        currency: currencyCart,
        emailType: emailTypeCart,
      };
      mutationCart.mutate(cartData, {
        onSuccess: (data) => {
          actions.updateDataAction({ cartId: data.cartId });
          setCart(data);
        },
      });
    } else {
      mutationCouponCheck.mutate(
        { couponCode: couponInput, country: selectedCountry },
        {
          onSuccess: (data) => {
            if (data.valid) {
              let cartData: GQL.MutationCartArgs = {
                cartId: state.data.cartId,
                couponCode: couponInput,
                language: languageCart,
                country: selectedCountry,
                currency: currencyCart,
                emailType: emailTypeCart,
              };
              mutationCart.mutate(cartData, {
                onSuccess: (data) => {
                  actions.updateDataAction({ cartId: data.cartId });
                  setCart(data);
                },
              });
            } else {
            }
          },
        }
      );
    }
  };

  const productToBaketAdd = (price: GQL.Price) => {};

  return (
    <div style={{ margin: "20px 0px 0 0" }}>
      <Card>
        <Title title="Playbrush Store" />
        <CardContent>
          <Typography
            variant="h1"
            sx={{
              fontSize: "20px",
              fontFamily: "Gotham",
              fontWeight: 600,
              m: 0,
              py: "4px",
              px: 0,
              color: theme.palette.primary.main,
            }}
          >
            Basket
          </Typography>
          <Root>
            {/* <Title title={title} defaultTitle={defaultTitle} />
            {shouldRenderEmptyPage ? renderEmpty() : renderList()}
            {aside} */}
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                  <Tab label="Products" {...a11yProps(0)} />
                  <Tab label="Bundles" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <TabPanel value={tabValue} index={0}>
                <div
                  style={{
                    padding: 0,
                    height: "calc(100vh - 300px)",
                    overflow: "auto",
                    backgroundColor: "#dbdbdb",
                    borderRadius: "10px",
                  }}
                >
                  {products.isLoading ? (
                    <div style={{ fontSize: 20, fontWeight: "bold" }}>Loading Products...</div>
                  ) : products.isError ? (
                    <div style={{ fontSize: 20, fontWeight: "bold" }}>Error: {products.error.message}</div>
                  ) : (
                    <>
                      {products.data!.length > 0 ? (
                        products.data!.map((product) => {
                          return (
                            <Card sx={{ display: "flex", margin: "20px" }} key={product.productId}>
                              {product.image && (
                                <CardMedia
                                  component="img"
                                  sx={{
                                    maxWidth: 100,
                                    maxHeight: 100,
                                    backgroundSize: "contain",
                                    objectFit: "contain",
                                  }}
                                  image={product.image}
                                  alt="Live from space album cover"
                                />
                              )}
                              {!product.image && <div style={{ width: 100, minWidth: 100, backgroundColor: "gray" }} />}
                              <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                <CardContent sx={{ flex: "1 0 auto" }}>
                                  <Typography component="div" variant="subtitle1">
                                    {product.name}
                                  </Typography>
                                  <Typography component="div" variant="subtitle2">
                                    {product &&
                                    product.priceRecurringInterval &&
                                    product.priceRecurringInterval !== "" ? (
                                      <Chip
                                        label="SUB"
                                        color="secondary"
                                        variant="outlined"
                                        style={{ fontSize: "10px" }}
                                      />
                                    ) : (
                                      <Chip label="ONE TIME" variant="outlined" style={{ fontSize: "10px" }} />
                                    )}
                                    {product &&
                                      product.priceRecurringInterval &&
                                      product.priceRecurringInterval !== "" && (
                                        <Chip
                                          label={"TRIAL " + product.priceRecurringTrialPeriodDays}
                                          color="primary"
                                          variant="outlined"
                                          style={{ fontSize: "10px" }}
                                        />
                                      )}
                                  </Typography>
                                </CardContent>
                              </Box>

                              {product && cart.currency && product.priceCurrency && (
                                <div style={{ display: "flex" }}>
                                  <Box
                                    sx={{
                                      minWidth: "60px",
                                      display: "flex",
                                      alignItems: "center",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      pl: 1,
                                      pb: 1,
                                      pr: 1,
                                      pt: 1,
                                    }}
                                  >
                                    {product.priceAmount !== null && product.priceAmount !== undefined && (
                                      <Box>{"Now "}</Box>
                                    )}
                                    {product.priceAmount !== null && product.priceAmount !== undefined && (
                                      <Currency price={product.priceAmount} currency={product.priceCurrency} />
                                    )}
                                    {product.priceAmountAfterTrial && <Box>{"Then "}</Box>}
                                    {product.priceAmountAfterTrial && (
                                      <Box>
                                        <Currency
                                          price={product.priceAmountAfterTrial}
                                          currency={product.priceCurrency}
                                        />
                                      </Box>
                                    )}
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      pl: 1,
                                      pb: 1,
                                      pr: 1,
                                      pt: 1,
                                    }}
                                  >
                                    <IconButton
                                      style={{
                                        backgroundColor: "#eee",
                                        borderRadius: "50px",
                                        fontSize: "18px",
                                      }}
                                      onClick={() => {
                                        setIsLoading(true);
                                        // basket.onItemAdded({
                                        //   id: price.priceId!,
                                        //   product_id: product.productId!,
                                        //   name: product.name || "",
                                        //   price: price.unitAmount || 0,
                                        //   price_id: price.priceId!,
                                        //   quantity: 1,
                                        // });
                                        let cartItemListSimple = cart!.lineItems!.map((lineItem) => {
                                          return { lineId: lineItem?.lineId, quantity: lineItem?.quantity };
                                        });
                                        let cartData: GQL.MutationCartArgs = {
                                          lineItems: [
                                            ...cartItemListSimple,
                                            {
                                              lineId: product.currentPriceEur,
                                              // product[
                                              //   "currentPrice" +
                                              //     cart.currency?.charAt(0).toUpperCase() +
                                              //     cart.currency?.slice(1)
                                              // ],
                                              quantity: 1,
                                            },
                                          ],
                                          cartId: state.data.cartId,
                                          country: selectedCountry,
                                          language: languageCart,
                                          currency: currencyCart,
                                          emailType: emailTypeCart,
                                        };
                                        mutationCart.mutate(cartData, {
                                          onSuccess: (data) => {
                                            setCart(data);
                                            setIsLoading(false);
                                          },
                                        });
                                      }}
                                      aria-label="add"
                                    >
                                      <Add sx={{ height: 20, width: 20 }} />
                                    </IconButton>
                                    <Link
                                      to={"/products/" + product.productId}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <IconButton
                                        style={{
                                          backgroundColor: "#eee",
                                          borderRadius: "50px",
                                          fontSize: "18px",
                                          marginTop: "6px",
                                        }}
                                        aria-label="edit"
                                      >
                                        <Edit sx={{ height: 20, width: 20 }} />
                                      </IconButton>
                                    </Link>
                                  </Box>
                                </div>
                              )}
                            </Card>
                          );
                        })
                      ) : (
                        <div style={{ fontSize: 20, fontWeight: "bold" }}>No Item found</div>
                      )}
                    </>
                  )}
                </div>
                <div>
                  Product Count:
                  {products.isLoading ? (
                    <span>Loading...</span>
                  ) : products.isError ? (
                    <span>N/A</span>
                  ) : (
                    <span>{products.data ? products.data.length : ""}</span>
                  )}
                </div>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <div
                  style={{
                    padding: 0,
                    height: "calc(100vh - 300px)",
                    overflow: "auto",
                    backgroundColor: "#dbdbdb",
                    borderRadius: "10px",
                  }}
                >
                  {bundles.isLoading ? (
                    <div style={{ fontSize: 20, fontWeight: "bold" }}>Loading Bundles...</div>
                  ) : bundles.isError ? (
                    <div style={{ fontSize: 20, fontWeight: "bold" }}>Error: {bundles.error.message}</div>
                  ) : (
                    <>
                      {bundles.data!.length > 0 ? (
                        bundles.data!.map((bundle) => {
                          return (
                            <Card sx={{ display: "flex", margin: "20px" }} key={bundle.bundleId}>
                              {bundle.image && (
                                <CardMedia
                                  component="img"
                                  sx={{
                                    maxWidth: 100,
                                    maxHeight: 100,
                                    backgroundSize: "contain",
                                    objectFit: "contain",
                                  }}
                                  image={bundle.image}
                                  alt="Live from space album cover"
                                />
                              )}
                              {!bundle.image && <div style={{ width: 100, minWidth: 100, backgroundColor: "gray" }} />}
                              <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                <CardContent sx={{ flex: "1 0 auto" }}>
                                  <Typography component="div" variant="subtitle1">
                                    {bundle.name}
                                    {"   "}
                                    {bundle.savingsEur}
                                  </Typography>
                                  {bundle.itemsExtended && bundle.itemsExtended.length > 0 ? (
                                    bundle.itemsExtended.map((item) => {
                                      return (
                                        <Typography
                                          component="div"
                                          variant="subtitle2"
                                          key={bundle.bundleId + "-" + item!.product?.productId}
                                        >
                                          <div style={{ padding: "4px 0px" }}>
                                            1 x {item && item.product && item.product.name}
                                          </div>
                                          <Typography component="div" variant="body2">
                                            {item &&
                                              item.product &&
                                              item.currency &&
                                              item.product.defaultPriceAmount !== null &&
                                              item.product.defaultPriceAmount !== undefined && (
                                                <Currency
                                                  price={item.product.defaultPriceAmount}
                                                  currency={item.currency}
                                                />
                                              )}
                                            {" -> "}
                                            {item &&
                                              item.product &&
                                              item.currency &&
                                              item.unitAmount !== null &&
                                              item.unitAmount !== undefined && (
                                                <Currency price={item.unitAmount} currency={item.currency} />
                                              )}
                                          </Typography>
                                          <Typography component="div" variant="body2">
                                            {item && item.recurringInterval && item.recurringInterval !== "" ? (
                                              <Chip
                                                label="SUB"
                                                color="secondary"
                                                variant="outlined"
                                                style={{ fontSize: "10px" }}
                                              />
                                            ) : (
                                              <Chip label="ONE TIME" variant="outlined" style={{ fontSize: "10px" }} />
                                            )}
                                            {item && item.recurringInterval && item.recurringInterval !== "" && (
                                              <Chip
                                                label={"TRIAL " + item.recurringTrialPeriodDays}
                                                color="primary"
                                                variant="outlined"
                                                style={{ fontSize: "10px" }}
                                              />
                                            )}
                                          </Typography>
                                        </Typography>
                                      );
                                    })
                                  ) : (
                                    <></>
                                  )}
                                </CardContent>
                              </Box>

                              {bundle && cart.currency && (
                                <div style={{ display: "flex" }}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      minWidth: "60px",
                                      alignItems: "center",
                                      pl: 1,
                                      pb: 1,
                                      pr: 1,
                                      pt: 1,
                                    }}
                                  >
                                    {bundle.priceAmountPayNowCurrentEur !== null && <Box>{"Now "}</Box>}
                                    {bundle.priceAmountPayNowCurrentEur !== null &&
                                      bundle.priceAmountPayNowCurrentEur !== undefined && (
                                        <Currency price={bundle.priceAmountPayNowCurrentEur} currency={cart.currency} />
                                      )}
                                    {bundle.priceAmountPayAfterTrialCurrentEur && <Box>{"Then "}</Box>}
                                    {bundle.priceAmountPayAfterTrialCurrentEur && (
                                      <Box>
                                        <Currency
                                          price={bundle.priceAmountPayAfterTrialCurrentEur}
                                          currency={cart.currency}
                                        />
                                      </Box>
                                    )}
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      pl: 1,
                                      pb: 1,
                                      pr: 1,
                                      pt: 1,
                                    }}
                                  >
                                    <IconButton
                                      style={{
                                        backgroundColor: "#eee",
                                        borderRadius: "50px",
                                        fontSize: "18px",
                                      }}
                                      onClick={() => {
                                        setIsLoading(true);
                                        // basket.onItemAdded({
                                        //   id: price.priceId!,
                                        //   product_id: product.productId!,
                                        //   name: product.name || "",
                                        //   price: price.unitAmount || 0,
                                        //   price_id: price.priceId!,
                                        //   quantity: 1,
                                        // });
                                        let cartItemListSimple = cart!.lineItems!.map((lineItem) => {
                                          return { lineId: lineItem?.lineId, quantity: lineItem?.quantity };
                                        });
                                        let cartData: GQL.MutationCartArgs = {
                                          lineItems: [...cartItemListSimple, { lineId: bundle.bundleId, quantity: 1 }],
                                          cartId: state.data.cartId,
                                          country: selectedCountry,
                                          language: languageCart,
                                          currency: currencyCart,
                                          emailType: emailTypeCart,
                                        };
                                        mutationCart.mutate(cartData, {
                                          onSuccess: (data) => {
                                            setCart(data);
                                            setIsLoading(false);
                                          },
                                        });
                                      }}
                                      aria-label="add"
                                    >
                                      <Add sx={{ height: 20, width: 20 }} />
                                    </IconButton>
                                    <Link to={"/bundles/" + bundle.bundleId} target="_blank" rel="noopener noreferrer">
                                      <IconButton
                                        style={{
                                          backgroundColor: "#eee",
                                          borderRadius: "50px",
                                          fontSize: "18px",
                                          marginTop: "6px",
                                        }}
                                        aria-label="edit"
                                      >
                                        <Edit sx={{ height: 20, width: 20 }} />
                                      </IconButton>
                                    </Link>
                                  </Box>
                                </div>
                              )}
                            </Card>
                          );
                        })
                      ) : (
                        <div style={{ fontSize: 20, fontWeight: "bold" }}>No Item found</div>
                      )}
                    </>
                  )}
                </div>
                <div>
                  Bundles Count:
                  {bundles.isLoading ? (
                    <span>Loading...</span>
                  ) : bundles.isError ? (
                    <span>N/A</span>
                  ) : (
                    <span>{bundles.data ? bundles.data.length : ""}</span>
                  )}
                </div>
              </TabPanel>
            </Box>

            <CardContent style={{ padding: "0 20px", width: "100%" }}>
              <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
                <Box>
                  <InputLabel
                    sx={{
                      fontSize: "13px",
                      fontFamily: "Gotham",
                      fontWeight: 600,
                      color: theme.palette.primary.main,
                    }}
                  >
                    Email Type
                  </InputLabel>
                  <ToggleButtonGroup
                    color="primary"
                    value={emailTypeCart}
                    exclusive
                    onChange={handleEmailTypeCartChange}
                    aria-label="Platform"
                    sx={{ height: "34px" }}
                  >
                    <ToggleButton value="">None</ToggleButton>
                    <ToggleButton value="order_confirmation">confirmation</ToggleButton>
                    <ToggleButton value="order_reminder">reminder</ToggleButton>
                    <ToggleButton value="order_renew">renew</ToggleButton>
                    <ToggleButton value="order_trial_ends">trial_ends</ToggleButton>
                    {/* <ToggleButton value="order_cancel">cancel</ToggleButton> */}
                  </ToggleButtonGroup>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  alignContent: "center",
                }}
              >
                <Box>
                  <InputLabel
                    sx={{
                      fontSize: "13px",
                      fontFamily: "Gotham",
                      fontWeight: 600,
                      color: theme.palette.primary.main,
                    }}
                  >
                    Country
                  </InputLabel>
                  <LocaleSelectMaterial
                    label="Locale"
                    value={locale}
                    onChange={handleLocaleChange}
                  ></LocaleSelectMaterial>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  alignContent: "center",
                }}
              >
                <Box>
                  <InputLabel
                    sx={{
                      fontSize: "13px",
                      fontFamily: "Gotham",
                      fontWeight: 600,
                      color: theme.palette.primary.main,
                    }}
                  >
                    Currency
                  </InputLabel>
                  {currencyCart}
                  {/* <ToggleButtonGroup
                    color="primary"
                    value={currencyCart}
                    exclusive
                    onChange={handleCurrencyCartChange}
                    aria-label="Platform"
                    sx={{ height: "34px" }}
                  >
                    <ToggleButton value="EUR">EUR</ToggleButton>
                    <ToggleButton value="GBP">GBP</ToggleButton>
                    <ToggleButton value="USD">USD</ToggleButton>
                  </ToggleButtonGroup> */}
                </Box>
                <Box>
                  <InputLabel
                    sx={{
                      fontSize: "13px",
                      fontFamily: "Gotham",
                      fontWeight: 600,
                      color: theme.palette.primary.main,
                    }}
                  >
                    Language
                  </InputLabel>
                  {languageCart}
                  {/* <ToggleButtonGroup
                    color="primary"
                    value={languageCart}
                    exclusive
                    onChange={handleLanguageCartChange}
                    aria-label="Platform"
                    sx={{ height: "34px" }}
                  >
                    {language_codes.map((language_code) => (
                      <ToggleButton key={language_code} value={language_code}>
                        {language_code}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup> */}
                </Box>
                <Box>
                  <InputLabel
                    sx={{
                      fontSize: "13px",
                      fontFamily: "Gotham",
                      fontWeight: 600,
                      color: theme.palette.primary.main,
                    }}
                  >
                    Country
                  </InputLabel>
                  {selectedCountry}
                </Box>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
                <Box>
                  <InputLabel
                    sx={{
                      fontSize: "13px",
                      fontFamily: "Gotham",
                      fontWeight: 600,
                      color: theme.palette.primary.main,
                    }}
                  >
                    Coupon
                  </InputLabel>
                  <RedditTextField
                    variant="filled"
                    label="Coupon Code"
                    name="couponCode"
                    onChange={onCouponInputChange}
                    type="text"
                    sx={{}}
                  ></RedditTextField>
                </Box>
                <Button
                  onClick={() => {
                    setCouponBtn();
                  }}
                  variant="outlined"
                  color="primary"
                  sx={{ fontSize: "10px", marginTop: "18px" }}
                >
                  Apply coupon
                </Button>
              </Box>
              <Box sx={{ marginBottom: "10px" }}>
                {mutationCouponCheck.isLoading
                  ? "Checking..."
                  : mutationCouponCheck.isSuccess && mutationCouponCheck.data.valid
                  ? cart.couponCode === ""
                    ? ""
                    : "Valid: persentage " +
                      mutationCouponCheck.data.percentage +
                      " | discount value " +
                      mutationCouponCheck.data.value
                  : "Not Valid"}
              </Box>
              {cart.lineItems !== undefined && cart.lineItems && cart.currency ? (
                <>
                  <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs value={tabValue2} onChange={handleTabChange2} aria-label="basic tabs example">
                        <Tab label="DEV" {...a11yProps(0)} />
                        <Tab label="ORDER" {...a11yProps(1)} />
                        <Tab label="MARKETING" {...a11yProps(2)} />
                      </Tabs>
                    </Box>
                    <TabPanel value={tabValue2} index={0}>
                      <CartList
                        cart={cart}
                        listType={"DEV"}
                        onDeleteLine={(record: any) => {
                          setIsLoading(true);
                          let cartItemListSimple = cart!.lineItems!.map((lineItem) => {
                            return { lineId: lineItem?.lineId, quantity: lineItem?.quantity };
                          });
                          let _items = [...cartItemListSimple];
                          const index = _items.findIndex((item) => item!.lineId === record.lineId);
                          if (index > -1) {
                            _items.splice(index, 1);
                          }
                          let cartData: GQL.MutationCartArgs = {
                            lineItems: _items,
                            cartId: state.data.cartId,
                          };
                          mutationCart.mutate(cartData, {
                            onSuccess: (data) => {
                              setCart(data);
                              setIsLoading(false);
                            },
                          });
                          // basket.onItemDeleted(record.id);
                          // setTimeout(() => {
                          // }, 1000);
                        }}
                        translation={translation}
                      />
                    </TabPanel>
                    <TabPanel value={tabValue2} index={1}>
                      <CartList cart={cart} listType={"ORDER"} translation={translation} />
                    </TabPanel>
                    <TabPanel value={tabValue2} index={2}>
                      <CartList cart={cart} listType={"MARKETING"} translation={translation} />
                    </TabPanel>
                  </Box>

                  {/* <Datagrid
                    data={cart.lineItems}
                    total={cart.lineItems.length}
                    sort={sort}
                    isLoading={isLoading}
                    isRowSelectable={() => false}
                    // selectedIds={[]}
                    setSort={() => {
                      console.log("set sort");
                    }}
                    // onSelect={() => {
                    //   console.log("on select");
                    // }}
                    onToggleItem={() => {
                      console.log("on toggle item");
                    }}
                  >
                    <TextField source="lineId" className="cart_line_id" />
                    <TextField source="quantity" />
                    <ImageField source="image" className="cart_image" />
                    <FunctionField
                      label="Price"
                      render={(record: { priceAmount: number; currency: string }) => {
                        return <Currency price={record.priceAmount} currency={cart.currency ? cart.currency : ""} />;
                      }}
                    />
                    <FunctionField
                      label="Price Default"
                      render={(record: { priceDefaultAmount: number; currency: string }) => {
                        return (
                          <Currency price={record.priceDefaultAmount} currency={cart.currency ? cart.currency : ""} />
                        );
                      }}
                    />
                    <TextField source="productName" />
                    <CustomButton
                      onClickHandler={(record: any) => {
                        setIsLoading(true);
                        let cartItemListSimple = cart!.lineItems!.map((lineItem) => {
                          return { lineId: lineItem?.lineId, quantity: lineItem?.quantity };
                        });
                        let _items = [...cartItemListSimple];
                        const index = _items.findIndex((item) => item!.lineId === record.lineId);
                        if (index > -1) {
                          _items.splice(index, 1);
                        }
                        let cartData: GQL.MutationCartArgs = {
                          lineItems: _items,
                          cartId: state.data.cartId,
                        };
                        mutationCart.mutate(cartData, {
                          onSuccess: (data) => {
                            setCart(data);
                            setIsLoading(false);
                          },
                        });
                        // basket.onItemDeleted(record.id);
                        // setTimeout(() => {
                        // }, 1000);
                      }}
                    ></CustomButton>
                  </Datagrid> */}
                  <div style={{ padding: "10px 20px 10px 10px", textAlign: "right" }}></div>
                  {/* <div style={{ padding: "10px 20px 10px 10px", textAlign: "right" }}>
                    Total:
                    {cart.lineItems.length > 1 &&
                      Math.round(
                        cart.lineItems.reduce(function (prev, current) {
                          return prev + current!.priceAmount! * current!.quantity!;
                        }, 0)
                      )}
                    {cart.lineItems.length === 0 && 0}
                  </div> */}
                  <div style={{ padding: "10px 20px 10px 10px", textAlign: "right" }}></div>
                  <div style={{ padding: "10px 20px 10px 10px", textAlign: "right" }}>
                    Items Count:{cart.lineItems.length}
                  </div>
                  <Typography variant="subtitle1" style={{ marginRight: 10 }}>
                    Subtotal:
                    <Currency price={cart.amountSubtotal!} currency={cart.currency} />
                  </Typography>
                  <Typography variant="subtitle1" style={{ marginRight: 10 }}>
                    Coupon Code: {cart.couponCode}
                  </Typography>
                  <Typography variant="subtitle1" style={{ marginRight: 10 }}>
                    Discount:
                    <Currency price={cart.amountDiscount!} currency={cart.currency} />
                  </Typography>
                  <Typography variant="subtitle1" style={{ marginRight: 10 }}>
                    Shipping:
                    <Currency price={cart.amountShipping!} currency={cart.currency} />
                  </Typography>
                  <Typography variant="subtitle1" style={{ marginRight: 10 }}>
                    VAT:
                    <Currency price={cart.amountTax!} currency={cart.currency} />
                  </Typography>
                  <Typography variant="subtitle1" style={{ marginRight: 10 }}>
                    Total:
                    <Currency price={cart.amountTotal!} currency={cart.currency} />
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                    {mutationCart.isError && <Box>{mutationCart.error.message}</Box>}
                    <Button
                      onClick={() => navigate("/checkout/" + state.data.cartId)}
                      variant="contained"
                      color="primary"
                      style={{ textTransform: "none" }}
                    >
                      Next
                    </Button>
                  </Box>
                </>
              ) : (
                ""
              )}
            </CardContent>
          </Root>
          {/* <CardActions style={{ justifyContent: "flex-end" }}>
            {mutationCart.isError && <Box>{mutationCart.error.message}</Box>}
            <Button
              onClick={() => navigate("/checkout/" + state.data.cartId)}
              variant="contained"
              color="primary"
              style={{ textTransform: "none" }}
            >
              Next
            </Button>
          </CardActions> */}

          <div>{processENV.REACT_APP_ENV_NAME}</div>
        </CardContent>
      </Card>
    </div>
  );
}

// export const Basket = BasketInner;

const PREFIX = "RaList";

export const ListClasses = {
  main: `${PREFIX}-main`,
  content: `${PREFIX}-content`,
  actions: `${PREFIX}-actions`,
  noResults: `${PREFIX}-noResults`,
};

const Root = styled("div", {
  name: PREFIX,
})(() => ({
  display: "flex",

  [`& .${ListClasses.main}`]: {
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
  },

  [`& .${ListClasses.content}`]: {
    position: "relative",
    // [theme.breakpoints.down('sm')]: {
    //     boxShadow: 'none',
    // },
    overflow: "inherit",
  },

  [`& .${ListClasses.actions}`]: {
    zIndex: 2,
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
  },

  [`& .${ListClasses.noResults}`]: { padding: 20 },
}));
