import { Box, Typography, IconButton, CardMedia } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";
import { styled, StyledEngineProvider, useTheme, Theme } from "@mui/material/styles";
import Currency from "./currency";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { __DEV__ } from "../api";
import { useTranslate } from "react-admin";
import getTranslationJson from "./getTranslationJson";

const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      maxWidth: 345,
    },
    media: {
      height: 140,
      "&:hover": {
        transform: "scale(1.5)",
      },
    },
    productImage: {
      maxWidth: 100,
      maxHeight: 100,
      minWidth: 80,
      backgroundSize: "contain",
      objectFit: "contain",
      borderRadius: "16px",
      background: "#e5e5e5",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        maxHeight: 80,
      },
    },
    productImageSmall: {
      minWidth: 60,
      maxWidth: 60,
      maxHeight: 60,
      backgroundSize: "contain",
      objectFit: "contain",
      borderRadius: "16px",
      background: "#e5e5e5",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        maxHeight: 80,
      },
    },
    productImageEmpty: {
      minWidth: 100,
      maxWidth: 100,
      maxHeight: 100,
      borderRadius: "16px",
      backgroundColor: "#dfdfdf",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        maxHeight: 60,
        maxWidth: 60,
      },
    },
    productImageEmptySmall: {
      minWidth: 60,
      minHeight: 60,
      maxWidth: 60,
      maxHeight: 60,
      borderRadius: "16px",
      backgroundColor: "#dfdfdf",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
    productContentLineInnerColText: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingRight: 16,
      paddingLeft: 16,
      flexGrow: 1,
    },
    productContentLineInnerColTextColumn: {
      display: "flex",
      flexDirection: "column",
      paddingRight: 16,
      paddingLeft: 16,
      justifyContent: "center",
      flexGrow: 1,
    },
    productDescription: {
      marginLeft: "36px",
    },
    productLine: {
      marginBottom: "14px",
    },
  };
});

const MainTitle = styled("h4")(({ theme }) => ({
  margin: 0,
  marginTop: "20px",
  fontWeight: 400,
  fontSize: "24px",
  fontFamily: "Gotham",
  // color: theme.palette.primary.main,
  [theme.breakpoints.down("sm")]: {
    fontSize: "20px",
  },
}));
const ItemsCount = styled("p")(({ theme }) => ({
  margin: 0,
  padding: 0,
  marginTop: "20px",
  // color: theme.palette.primary.main,
  fontFamily: "Gotham",
  fontWeight: 400,
  fontSize: "24px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
  },
}));
const LineTitle = styled("span")(({ theme }) => ({
  margin: 0,
  padding: 0,
  // color: theme.palette.primary.main,
  fontFamily: "Gotham",
  fontWeight: 400,
  fontSize: "24px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
  },
}));
const ProductName = styled("div")(({ theme }) => ({
  ...theme.typography.h5,
  // color: theme.palette.primary.main,
  fontFamily: "Gotham",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));
const QuantityBox = styled("div")(({ theme }) => ({
  width: "24px",
  height: "24px",
  boxShadow: "0px 2px 8px  rgba(0, 0, 0, 0.1)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  borderRadius: "4px",
  marginRight: "12px",
  minWidth: "24px",
  maxWidth: "24px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));
const ProductNameSmall = styled("span")(({ theme }) => ({
  ...theme.typography.body1,
  // color: theme.palette.primary.main,
  fontFamily: "Gotham",
  fontWeight: 700,
  fontSize: "12px",
  lineHeight: "12px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
  },
}));
const ProductDescription = styled("div")(({ theme }) => ({
  ...theme.typography.body1,
  // color: theme.palette.primary.main,
  fontFamily: "Gotham",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "14px",
  marginTop: "6px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
  },
}));
const QuantityText = styled("span")(({ theme }) => ({
  ...theme.typography.body1,
  // color: theme.palette.primary.main,
  fontFamily: "Gotham",
  fontWeight: 700,
  fontSize: "12px",
  lineHeight: "24px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));
const PriceBrushText = styled("span")(({ theme }) => ({
  fontWeight: 400,
  fontSize: "14px",
  fontFamily: "Gotham",
  // color: theme.palette.primary.main,
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));
const PriceCurrentText = styled("span")(({ theme }) => ({
  fontWeight: 500,
  fontSize: "18px",
  fontFamily: "Gotham",
  // color: theme.palette.primary.main,
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
}));
const PriceDefaultText = styled("span")(({ theme }) => ({
  fontWeight: 400,
  textDecoration: "line-through",
  fontSize: "14px",
  fontFamily: "Gotham",
  // color: theme.palette.primary.main,
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));
const PriceLaterText = styled("span")(({ theme }) => ({
  fontWeight: 400,
  fontSize: "12px",
  fontFamily: "Gotham",
  color: theme.palette.primary.main,
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));

const GrayLine = styled("div")(({ theme }) => ({
  borderBottom: "1px solid",
  borderColor: theme.palette.mode === "dark" ? "#CDD4DC" : "#CDD4DC",
  margin: "24px 0px",
}));
const GrayBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#F8F8F8",
  border: "1px solid",
  borderColor: theme.palette.mode === "dark" ? "#444d58" : "#CDD4DC",
  padding: "32px",
  borderRadius: "16px",
  fontFamily: "Gotham",
  color: theme.palette.primary.main,
}));

type Props = {
  order: GQL.Orders;
  listType?: "DEV" | "ORDER" | "MARKETING";
  onDeleteLine?: (record: any) => void;
  translation?: GQL_AEM.CheckoutTranslationModels | null;
};

var groupBy = function (array: any) {
  var result: any = [];
  array.reduce(function (res: any, value: GQL.OrderItem) {
    if (value !== null && value !== undefined && value.productId !== null && value.productId !== undefined) {
      if (!res[value.productId]) {
        res[value.productId] = { quantity: 0, priceAmount: 0, ...value };
        res[value.productId].quantity = 0;
        res[value.productId].priceAmount = 0;
        result.push(res[value.productId]);
      }
      res[value.productId].quantity += value.quantity;
      res[value.productId].priceAmount = value.priceAmount;

      return res;
    }
  }, {});
  return result;
};

const OrderList: React.FC<Props> = (props: Props) => {
  const theme = useTheme();
  const { classes } = useStyles();
  const translate = useTranslate();

  const order = props.order;
  const listType = props.listType !== undefined ? props.listType : "ORDER";

  // let orderItemsGroupedByProduct = [];
  // const groupedObj = groupBy(order.orderItems, "productId")
  // groupedObj.values().map((arrayList:any) => {

  //   orderItemsGroupedByProduct.push()
  // })

  order.orderItems = groupBy(order.orderItems);
  const translation = getTranslationJson("en");

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        {/* <Typography variant="h6" gutterBottom>
          {translate("resources.commands.section.items")}
        </Typography> */}
        <MainTitle>{translation?.order_overview || "Order overview"}</MainTitle>
        <ItemsCount>
          ({order.orderItems && order.orderItems.length} {translation?.items || "items"})
        </ItemsCount>
      </Box>
      <GrayLine />
      {order.orderItems!.length > 0 ? (
        order.orderItems!.map((lineItem) => {
          return (
            <Box key={lineItem?.productId + "_order"}>
              {listType === "DEV" ? <Box>{lineItem?.id}</Box> : <></>}
              {/* {lineItem?.lineContent &&
                lineItem?.lineContent.length === 0 &&
                lineItem?.tags &&
                lineItem?.tags.includes("toothbrush") && <ProductName>{translation?.group_title_brush}</ProductName>}
              {lineItem?.lineContent &&
                lineItem?.lineContent.length === 0 &&
                lineItem?.tags &&
                (lineItem?.tags.includes("brush_heads") || lineItem?.tags.includes("brushheads")) && (
                  <ProductName>{translation?.group_title_brush_head}</ProductName>
                )}
              {lineItem?.lineContent && lineItem?.lineContent.length > 0 && (
                <ProductName>
                  {lineItem?.productName}
                  {listType === "DEV" && lineItem?.productName}
                  {listType === "ORDER" && lineItem?.productNameOrder}
                  {listType === "MARKETING" && lineItem?.productNameMarketing}
                </ProductName>
              )} */}
              <Box sx={{ display: "flex", margin: "24px 0px 32px 0px" }}>
                {lineItem?.image && (
                  <CardMedia
                    component="img"
                    className={classes.productImage}
                    image={lineItem?.image}
                    alt="product image"
                  />
                )}
                {!lineItem?.image && <div className="productImageEmpty" />}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    justifyContent: "center",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <QuantityBox>
                          <QuantityText>
                            {lineItem && lineItem.quantity && lineItem.quantity && lineItem.quantity}x
                          </QuantityText>
                        </QuantityBox>
                        <ProductName>
                          {lineItem?.productName}
                          {/* {listType === "DEV" && lineItem?.productName}
                          {listType === "ORDER" && lineItem?.productNameOrder}
                          {listType === "MARKETING" && lineItem?.productNameMarketing} */}
                        </ProductName>
                      </Box>
                      {/* <ProductDescription className={classes.productDescription}>
                        {lineItem && lineItem?.description}
                      </ProductDescription> */}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        minWidth: "100px",
                      }}
                    >
                      {lineItem &&
                        order.currency &&
                        lineItem.priceAmount !== undefined &&
                        lineItem.priceAmount !== null &&
                        lineItem.quantity !== undefined &&
                        lineItem.quantity !== null && (
                          <>
                            {/* {lineItem.tags && lineItem.priceAmount !== 0 && lineItem.tags.includes("toothbrush") && (
                              <PriceBrushText>
                                {lineItem.priceAmount !== 0 && (translation?.brush || "Bursh")}
                              </PriceBrushText>
                            )}
                            {lineItem.tags &&
                              lineItem.priceAmount !== 0 &&
                              (lineItem.tags.includes("brush_heads") || lineItem.tags.includes("brushheads")) && (
                                <PriceBrushText>{translation?.brush_head || "Bursh head"}</PriceBrushText>
                              )} */}
                            <PriceCurrentText>
                              {/* {lineItem.priceAmount === 0 ? (
                                translation?.free_trial || "Free Trial"
                              ) : ( */}
                              <Currency price={lineItem.priceAmount * lineItem.quantity} currency={order.currency} />
                              {/* )} */}
                            </PriceCurrentText>
                          </>
                        )}
                      {/* {lineItem && order.currency && lineItem.priceAmountAfterTrial && lineItem.quantity && (
                        <>
                          <PriceLaterText>{translation?.then || "then"} </PriceLaterText>
                          <PriceLaterText>
                            <Currency
                              price={lineItem.priceAmountAfterTrial * lineItem.quantity}
                              currency={order.currency}
                            />{" "}
                          </PriceLaterText>
                          <PriceLaterText>
                            {lineItem.recurringIntervalCount !== undefined &&
                              lineItem.recurringIntervalCount !== null &&
                              lineItem.recurringIntervalCount > 1 &&
                              lineItem.recurringIntervalCount}
                            {translation?.every || "every"}{" "}
                            {lineItem.recurringInterval === "day" && (translation?.day || "day")}
                            {lineItem.recurringInterval === "month" && (translation?.month || "month")}
                            {lineItem.recurringInterval === "year" && (translation?.year || "year")}
                          </PriceLaterText>
                        </>
                      )} */}
                    </Box>
                  </Box>
                </Box>
              </Box>
              {listType === "DEV" && (
                <Box sx={{ textAlign: "right" }}>
                  <IconButton
                    style={{
                      //   backgroundColor: "#eee",
                      borderRadius: "50px",
                      fontSize: "18px",
                    }}
                    onClick={() => {
                      if (props.onDeleteLine) {
                        props.onDeleteLine(lineItem);
                      }
                    }}
                    aria-label="add"
                  >
                    <DeleteOutlineOutlinedIcon sx={{ height: 20, width: 20 }} />
                  </IconButton>
                </Box>
              )}
              <GrayLine />
            </Box>
          );
        })
      ) : (
        <>
          <div
            style={{
              textAlign: "center",
              fontWeight: 400,
              fontSize: "24px",
              fontFamily: "Gotham",
              color: theme.palette.primary.main,
            }}
          >
            {translation?.cart_has_no_items || "No Item in your order"}
          </div>
          <GrayLine />
        </>
      )}
    </>
  );
};

export default OrderList;
