import * as React from "react";
import { useState, useEffect } from "react";
import { Card, CardContent, Box } from "@mui/material";
import { Title } from "react-admin";
import { Appearance, loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { Elements, ElementsConsumer, PaymentElement } from "@stripe/react-stripe-js";
import { useStateMachine } from "little-state-machine";
import StripeFormPaymentIntent from "../components/stripeFormPaymentIntent";
import StripeFormSetupIntent from "../components/stripeFormSetupIntent";
// import { CheckoutForm } from "../components/stripeForm";
import { useCheckoutSubmit } from "../hooks/useCheckout";
import { useBasket } from "../basket/basketContext";
import { CheckoutTitle } from "./checkoutCustomer";
import Accordion, { AccordionProps } from "@mui/material/Accordion";
import { styled } from "@mui/material/styles";
import AccordionDetails from "@mui/material/AccordionDetails";
//import AccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import useScript from "../hooks/useScript";
import PaypalForm from "../components/paypalForm";
import CircularProgress from "@mui/material/CircularProgress";
import CartList from "../components/cartList";
import { useMutation, UseMutationOptions, UseMutationResult, useQuery, UseQueryResult } from "react-query";
import { ClientError } from "graphql-request";
import { __DEV__, PAGEVIEW, processENV } from "../api";
import { stripeElementAppearance } from "../themes";
import { addPageViewTrackingEvent } from "../components/trackingEvents";
import MessageConfirmModal from "../components/messageConfirmModal";

const AccordionSummary = styled((props: AccordionSummaryProps & { expandedaccordion: number }) => (
  <MuiAccordionSummary
    expandIcon={
      !!props.expandedaccordion ? (
        <RadioButtonCheckedIcon sx={{ fontSize: "20px", color: "#2bb573" }} />
      ) : (
        <RadioButtonUncheckedIcon sx={{ fontSize: "20px", color: "#8193A8" }} />
      )
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "#FFFFFF",
  flexDirection: "row-reverse",
  "&.MuiAccordionSummary-root": {
    borderRadius: "12px",
    padding: "24px 26px 24px 26px",
  },
  ".Mui-expanded&.MuiAccordionSummary-root": {
    padding: "24px 26px 0px 26px",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    // transform: "rotate(90deg)",
    // marginLeft: "6px",
    // marginRight: "6px",
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    margin: "12px 0 12px 6px",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: "6px",
    color: "#8193A8",
    fontFamily: "Gotham",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
  },
  ".Mui-expanded& .MuiAccordionSummary-content": {
    color: theme.palette.primary.main,
  },
}));

const BlueAccordion = styled((props: AccordionProps) => (
  <Accordion sx={{ borderColor: props.expanded ? "#231f20" : "#CDD4DC" }} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "#FFFFFF",
  flexDirection: "row-reverse",
  "&.MuiAccordion-root": {
    border: "1px solid ",
    borderRadius: "12px",
    borderColor: "#CDD4DC",
    boxShadow: "none",
    marginBottom: "20px",
  },
  "&.MuiAccordion-root.Mui-expanded": {
    borderColor: theme.palette.primary.main,
  },
}));

const stripePromise = loadStripe(processENV.REACT_APP_STRIPE_CLIENT_SECRET || "", {
  apiVersion: processENV.REACT_APP_STRIPE_API_VERSION,
});

const Payment = ({
  cartData,
  translation,
  mutationPaypalSessionPay,
  setActiveStep,
  setPaypalPaymentLoading,
  setMessageSnackBarOpen,
  setMessageSnackBar,
  payNowButtonRef,
  isLoadingPayNowConfirm,
  setIsLoadingPayNowConfirm,
}: {
  cartData: GQL.CheckoutOpen | GQL.CheckoutUpdate | null;
  translation: GQL_AEM.CheckoutTranslationModels | null;
  mutationPaypalSessionPay: UseMutationResult<
    GQL.PaypalSessionPay,
    ClientError,
    GQL.MutationPaypalSessionPayArgs,
    unknown
  >;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setPaypalPaymentLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setMessageSnackBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setMessageSnackBar: React.Dispatch<React.SetStateAction<string>>;
  payNowButtonRef: React.MutableRefObject<HTMLButtonElement | null>;
  isLoadingPayNowConfirm: boolean;
  setIsLoadingPayNowConfirm: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [stripeClientSecret, setStripeClientSecret] = useState("");
  const [paypalClientToken, setPaypalClientToken] = useState("");
  const [cart_has_subscription, setCart_has_subscription] = useState(false);
  // const mutationCheckoutSessionCreate = useCheckoutSessionCreate();
  // const mutationOrder = useOrder();
  // const mutationOrderSubmit = useOrderSubmit();
  const mutationCheckoutSubmit = useCheckoutSubmit();
  const { state } = useStateMachine();
  // const basket = useBasket();

  const [expandedAccordion, setExpandedAccordion] = React.useState<string>("");

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedAccordion(isExpanded ? panel : "");
  };

  useEffect(() => {
    if (__DEV__) {
      console.log("StripePromise", stripePromise);
    }
    if (mutationCheckoutSubmit.isLoading === false) {
      mutationCheckoutSubmit.mutate(
        {
          checkoutId: state.data.checkoutId,
        },
        {
          onSuccess: (data) => {
            setPaypalClientToken(data.paypalClientToken ? data.paypalClientToken : "");
            setStripeClientSecret(data.stripeClientSecret ? data.stripeClientSecret : "");
            setCart_has_subscription(data.hasSubscription ? data.hasSubscription : false);
          },
        }
      );
    }

    addPageViewTrackingEvent(cartData, PAGEVIEW.CHECKOUT_REGULAR);

    return function cleanup() {
      if (__DEV__) {
        console.log("cleanup");
      }
    };
  }, []);

  const options: StripeElementsOptions = {
    clientSecret: stripeClientSecret,
    appearance: stripeElementAppearance,
  };
  return (
    <div className="payment" style={{ margin: "20px 0px 0 0" }}>
      <>
        {/* <Title title="Playbrush Store" /> */}
        {/* <CardContent> */}

        <CheckoutTitle>{translation?.step_payment || "Payment"}</CheckoutTitle>
        {stripeClientSecret && mutationCheckoutSubmit.isLoading === false && (
          <>
            <Elements stripe={stripePromise} options={options}>
              {cartData !== null &&
                (cartData.amountTotal === 0 ? (
                  <StripeFormSetupIntent
                    payNowButtonRef={payNowButtonRef}
                    isLoadingPayNowConfirm={isLoadingPayNowConfirm}
                    setIsLoadingPayNowConfirm={setIsLoadingPayNowConfirm}
                  />
                ) : cartData.hasSubscription ? (
                  <StripeFormPaymentIntent
                    payNowButtonRef={payNowButtonRef}
                    isLoadingPayNowConfirm={isLoadingPayNowConfirm}
                    setIsLoadingPayNowConfirm={setIsLoadingPayNowConfirm}
                  />
                ) : (
                  <StripeFormPaymentIntent
                    payNowButtonRef={payNowButtonRef}
                    isLoadingPayNowConfirm={isLoadingPayNowConfirm}
                    setIsLoadingPayNowConfirm={setIsLoadingPayNowConfirm}
                  />
                ))}
            </Elements>
          </>
        )}
        {mutationCheckoutSubmit.isLoading && (
          <Box
            sx={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              minHeight: "300px",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {false && expandedAccordion !== "false" && (
          <div>
            <BlueAccordion expanded={expandedAccordion === "panel1" ? true : false} onChange={handleChange("panel1")}>
              <AccordionSummary
                expandedaccordion={expandedAccordion === "panel1" ? 1 : 0}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                Debit/Credit card
              </AccordionSummary>
              <AccordionDetails>
                {stripeClientSecret && mutationCheckoutSubmit.isLoading === false && (
                  <>
                    <Elements stripe={stripePromise} options={options}>
                      {cartData !== null &&
                        (cartData!.amountTotal === 0 ? (
                          <StripeFormSetupIntent
                            payNowButtonRef={payNowButtonRef}
                            isLoadingPayNowConfirm={isLoadingPayNowConfirm}
                            setIsLoadingPayNowConfirm={setIsLoadingPayNowConfirm}
                          />
                        ) : cartData!.hasSubscription ? (
                          <StripeFormPaymentIntent
                            payNowButtonRef={payNowButtonRef}
                            isLoadingPayNowConfirm={isLoadingPayNowConfirm}
                            setIsLoadingPayNowConfirm={setIsLoadingPayNowConfirm}
                          />
                        ) : (
                          <StripeFormPaymentIntent
                            payNowButtonRef={payNowButtonRef}
                            isLoadingPayNowConfirm={isLoadingPayNowConfirm}
                            setIsLoadingPayNowConfirm={setIsLoadingPayNowConfirm}
                          />
                        ))}
                    </Elements>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontFamily: "Gotham",
                        fontWeight: 500,
                        color: "gray",
                        fontSize: "12px",
                        marginTop: "6px",
                      }}
                    >
                      Powered by{" "}
                      <svg
                        className="InlineSVG Icon Footer-PoweredBy-Icon Icon--md "
                        focusable="false"
                        width="38"
                        height="30"
                        viewBox="0 0 33 15"
                        role="img"
                        aria-labelledby="stripe-title"
                        style={{
                          filter:
                            "invert(41%) sepia(23%) saturate(1981%) hue-rotate(197deg) brightness(92%) contrast(93%)",
                          marginLeft: "5px",
                        }}
                      >
                        <title id="stripe-title">Stripe</title>
                        <g fillRule="evenodd">
                          <path d="M32.956 7.925c0-2.313-1.12-4.138-3.261-4.138-2.15 0-3.451 1.825-3.451 4.12 0 2.719 1.535 4.092 3.74 4.092 1.075 0 1.888-.244 2.502-.587V9.605c-.614.307-1.319.497-2.213.497-.876 0-1.653-.307-1.753-1.373h4.418c0-.118.018-.588.018-.804zm-4.463-.859c0-1.02.624-1.445 1.193-1.445.55 0 1.138.424 1.138 1.445h-2.33zM22.756 3.787c-.885 0-1.454.415-1.77.704l-.118-.56H18.88v10.535l2.259-.48.009-2.556c.325.235.804.57 1.6.57 1.616 0 3.089-1.302 3.089-4.166-.01-2.62-1.5-4.047-3.08-4.047zm-.542 6.225c-.533 0-.85-.19-1.066-.425l-.009-3.352c.235-.262.56-.443 1.075-.443.822 0 1.391.922 1.391 2.105 0 1.211-.56 2.115-1.39 2.115zM18.04 2.766V.932l-2.268.479v1.843zM15.772 3.94h2.268v7.905h-2.268zM13.342 4.609l-.144-.669h-1.952v7.906h2.259V6.488c.533-.696 1.436-.57 1.716-.47V3.94c-.289-.108-1.346-.307-1.879.669zM8.825 1.98l-2.205.47-.009 7.236c0 1.337 1.003 2.322 2.34 2.322.741 0 1.283-.135 1.581-.298V9.876c-.289.117-1.716.533-1.716-.804V5.865h1.716V3.94H8.816l.009-1.96zM2.718 6.235c0-.352.289-.488.767-.488.687 0 1.554.208 2.241.578V4.202a5.958 5.958 0 0 0-2.24-.415c-1.835 0-3.054.957-3.054 2.557 0 2.493 3.433 2.096 3.433 3.17 0 .416-.361.552-.867.552-.75 0-1.708-.307-2.467-.723v2.15c.84.362 1.69.515 2.467.515 1.879 0 3.17-.93 3.17-2.548-.008-2.692-3.45-2.213-3.45-3.225z"></path>
                        </g>
                      </svg>
                    </Box>
                  </>
                )}
                {mutationCheckoutSubmit.isLoading && (
                  <Box
                    sx={{
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      minHeight: "300px",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
              </AccordionDetails>
            </BlueAccordion>
            <BlueAccordion expanded={expandedAccordion === "panel2" ? true : false} onChange={handleChange("panel2")}>
              <AccordionSummary
                expandedaccordion={expandedAccordion === "panel2" ? 1 : 0}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                Paypal
              </AccordionSummary>
              <AccordionDetails>
                {/* paypalClientToken !== ""  &&  */}
                {mutationCheckoutSubmit.isLoading === false && (
                  <PaypalForm
                    checkoutId={state.data.checkoutId}
                    paypalClientToken={paypalClientToken}
                    mutationPaypalSessionPay={mutationPaypalSessionPay}
                    cartData={cartData}
                    translation={translation}
                    setActiveStep={setActiveStep}
                    setPaypalPaymentLoading={setPaypalPaymentLoading}
                    setMessageSnackBarOpen={setMessageSnackBarOpen}
                    setMessageSnackBar={setMessageSnackBar}
                  />
                )}
              </AccordionDetails>
            </BlueAccordion>
            {/* <BlueAccordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
            <AccordionSummary
              expandIcon={expanded === "panel3" ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>Advanced settings</Typography>
              <Typography sx={{ color: "text.secondary" }}>
                Filtering has been entirely disabled for whole web server
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit amet egestas eros, vitae egestas
                augue. Duis vel est augue.
              </Typography>
            </AccordionDetails>
          </BlueAccordion>
          <BlueAccordion expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
            <AccordionSummary
              expandIcon={expanded === "panel4" ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>Personal data</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit amet egestas eros, vitae egestas
                augue. Duis vel est augue.
              </Typography>
            </AccordionDetails>
          </BlueAccordion> */}
          </div>
        )}
        {/* </CardContent> */}
        {cartData && <CartList cart={cartData} listType={"MARKETING"} translation={translation} />}
      </>
    </div>
  );
};

export default Payment;
