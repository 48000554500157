import { Badge } from "@mui/material";
import { useCallback } from "react";
import {
  ChipField,
  Create,
  CreateButton,
  Datagrid,
  Labeled,
  EditButton,
  FilterButton,
  useUpdate,
  List,
  Pagination,
  BulkDeleteButton,
  SaveButton,
  DeleteButton,
  SelectColumnsButton,
  SimpleForm,
  SingleFieldList,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  WrapperField,
  useNotify,
  useRedirect,
  UrlField,
  useCreate,
  Edit
} from "react-admin";
import { Grid, Link } from "@mui/material";

const AppRedirectLinkBulkActionButtons = () => (
  <>
    <BulkDeleteButton label="Archive & Deactivate" />
  </>
);
const MyPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]} />;
const MyToolbar = (props: any) => {
  const redirect = useRedirect();
  const notify = useNotify();
  return (
    <Toolbar {...props} sx={{ justifyContent: "space-between" }}>
      <SaveButton label="Update" />
      <DeleteButton label="Archive & Deactivate" sx={{ textAlign: "right" }} />
    </Toolbar>
  );
};

export const AppRedirectLinkList = () => {

    const bundleFilters = [<TextInput label="Link Type" source="link_type__icontains" alwaysOn />];
        
      return (
        <List filters={bundleFilters} sort={{ field: 'linkType', order: 'ASC' }} pagination={<MyPagination />}>
          <Datagrid bulkActionButtons={<AppRedirectLinkBulkActionButtons />}>
            <TextField source="linkType" />
            <TextField label="Languages" reference="language" source="language">
              <SingleFieldList>
                <Badge color="secondary" badgeContent={<TextField source="supportedLanguages.length" />}>
                  <ChipField source="linkType" />
                </Badge>
              </SingleFieldList>
            </TextField>
            
            <UrlField source="value"  />
    
            <WrapperField label="Actions">
              <EditButton />
            </WrapperField>
          </Datagrid>
        </List>
      );
    };

const AppRedirectLinkCreateEditForm: React.FC = () => {

  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={0} sm={2}></Grid>
      <Grid item xs={12} sm={8}>
        <Labeled label="Link Type" fullWidth={true}>
          <TextInput source="linkType" helperText = "Type of the link eg. privacy_policy" fullWidth={true} />
        </Labeled>
      </Grid>
      <Grid item xs={0} sm={2}></Grid>
      <Grid item xs={0} sm={2}></Grid>
      <Grid item xs={12} sm={8}>
        <Labeled label="Language " fullWidth={true}>
          <TextInput fullWidth={true} label="Language" source="language"  helperText="Language of the page" />
        </Labeled>
        <Labeled label="Link of the page" fullWidth={true}>

          <TextInput fullWidth={true} label="Value" source="value" helperText="Link of the page" />
        </Labeled>

      </Grid>
      <Grid item xs={0} sm={2}></Grid>
      <Grid item xs={0} sm={2}></Grid>
      <Grid item xs={0} sm={2}></Grid>
      <Grid item xs={6} sm={5} sx={{ textAlign: "right" }}>
      </Grid>
      <Grid item xs={0} sm={2}></Grid>
      <Grid item xs={6} sm={5}>
         
      </Grid>
    </Grid>
  );
};

export const AppRedirectLinkCreate = () => {
  const [create] = useCreate();
  const save = useCallback(
    async (values: any) => {
      try {
        await create("appRedirectLink", { data: values }, { returnPromise: true });
      } catch (error: any) {
        if (error!.body!.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body.errors;
        }
      }
    },
    [create]
  );

  return (
    <Create>
      <SimpleForm>
        <AppRedirectLinkCreateEditForm></AppRedirectLinkCreateEditForm>
      </SimpleForm>
    </Create>
  );
};


export const AppRedirectLinkEdit = () => {
  const [update, { isLoading, error }] = useUpdate();
  const save = useCallback(
    async (values: any) => {
      try {
        await update(
          "appRedirectLink",
          { id: values.id, data: values, previousData: values },
          { returnPromise: true }
        );
      } catch (error: any) {
        if (error!.body!.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body.errors;
        }
      }
    },
    [update]
  );
  
  return (
    <Edit mutationMode="pessimistic">
      <SimpleForm toolbar={<MyToolbar />}>
        <AppRedirectLinkCreateEditForm></AppRedirectLinkCreateEditForm>
      </SimpleForm>
    </Edit>
  );
};

export const MyActions = ({ showCreate = false }) => {
  return (
    <TopToolbar>
      {showCreate ? <CreateButton /> : ""}
      <FilterButton />
      <SelectColumnsButton />
    </TopToolbar>
  );
};




