import * as React from "react";
import { Title } from "react-admin";
import {
  Paper,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  TextField,
  FormControlLabel,
  Box,
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
  Checkbox,
  Grid,
  TextFieldProps,
  useTheme,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { createStore, useStateMachine, StateMachineProvider, GlobalState } from "little-state-machine";
import { useForm, UseFormRegister, FieldValues, Controller, Control } from "react-hook-form";
import styled from "@mui/system/styled";
import MuiPhoneNumber from "material-ui-phone-number";
import RedditTextField, { RedditPhoneField } from "../components/redditTextfield";
// import CountryRegionSelect from "../components/countryRegionSelect";
import CheckBoxCheckedIcon, { CheckBoxUnCheckedIcon } from "../components/checkBoxCheckedIcon";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
// import { allCountries } from "country-region-data";
import allCountriesjson from "country-region-data/data.json";
import { CountryDataType, RegionDataType } from "../globalState/global";
// import { CountryData as CountryDataType } from "country-region-data";
import { GetEmailRegex } from "../components/utils";
import CartList from "../components/cartList";
import { CountrySelectMaterial, StateSelectMaterial } from "../components/countySelect";
import userTimeZoneCountryCode from "../components/userTimeZoneCountryCode";
import StripeExpressCheckout from "../components/stripeExpressCheckout";
import StripeExpressCheckoutElement from "../components/stripeExpressCheckoutElement";
import { themeDark } from "../themes";
import { allowed_shipping_countries } from "../api";

const getCountry = (inputValue: string): CountryDataType | null => {
  const selectedCountry = allCountriesjson.filter(
    (e) => e.countryShortCode === inputValue.toUpperCase() || e.countryName === inputValue
  );
  if (selectedCountry.length > 0) {
    return selectedCountry[0] ? selectedCountry[0] : null;
  } else {
    return null;
  }
};

const getRegion = (regionName: string): RegionDataType | null => {
  return regionName ? { name: regionName } : null;
  // const selectedCountry = allCountriesList.filter((e) => e.countryShortCode === countryShortCode.toUpperCase());
  // if (selectedCountry.length > 0) {
  //   return selectedCountry[0].regions ? selectedCountry[0].regions : [];
  // } else {
  //   return [];
  // }
};

const getRegions = (countryShortCode: string, allCountriesList: CountryDataType[]): RegionDataType[] | [] => {
  const selectedCountry = allCountriesList.filter((e) => e.countryShortCode === countryShortCode.toUpperCase());
  if (selectedCountry.length > 0) {
    return selectedCountry[0].regions ? selectedCountry[0].regions : [];
  } else {
    return [];
  }
};

export const CheckoutTitle = styled("div")(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  // border: "1px solid",
  // borderColor: theme.palette.mode === "dark" ? "#444d58" : "#ced7e0",
  // padding: theme.spacing(1),
  // borderRadius: "4px",
  // textAlign: "center",
  fontFamily: "Gotham",
  fontSize: "20px",
  lineHeight: "20px",
  fontWeight: "400",
  color: theme.palette.primary.main,
  marginBottom: "24px",
}));

const countryFilterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: (option: CountryDataType) => option.countryShortCode + option.countryName,
});
const regionFilterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: (option: RegionDataType) => option.name,
});

const AUTOFILLED = "is-autofilled";
const onAutoFillStart = (el: any) => el.classList.add(AUTOFILLED);
const onAutoFillCancel = (el: any) => el.classList.remove(AUTOFILLED);
const onAnimationStart = (e: React.AnimationEvent<HTMLDivElement>) => {
  // console.log(e.target);
  // const input = e.target as HTMLInputElement;
  // console.log(input.value);
  switch (e.animationName) {
    case "onAutoFillStart":
      return onAutoFillStart(e.target);
    case "onAutoFillCancel":
      return onAutoFillCancel(e.target);
  }
};

const Customer = ({
  register,
  control,
  errors,
  getValues,
  setValue,
  cartData,
  translation,
  setMessageSnackBarOpen,
  setMessageSnackBar,
}: {
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues, any>;
  errors: {
    [x: string]: any;
  };
  getValues: any;
  setValue: any;
  cartData: GQL.CheckoutOpen | GQL.CheckoutUpdate | null;
  translation: GQL_AEM.CheckoutTranslationModels | null;
  setMessageSnackBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setMessageSnackBar: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { state } = useStateMachine();
  const theme = useTheme();

  const [billingOpen, setBillingOpen] = React.useState(getValues("billing_different"));
  const [regionsOpen, setRegionsOpen] = React.useState(false);
  const [regionsOptions, setRegionsOptions] = React.useState<readonly RegionDataType[]>([]);
  const regionsLoading = regionsOpen && regionsOptions.length === 0;

  const [selectedCountry, setSelectedCountry] = React.useState<string>("");
  const [selectedState, setSelectedState] = React.useState<string>("");

  // React.useEffect(() => {
  //   let active = true;

  //   if (!regionsLoading) {
  //     return undefined;
  //   }

  //   (async () => {
  //     if (active) {
  //       setRegionsOptions(
  //         getRegions(getValues("country") ? getValues("country").countryShortCode : "", allCountriesjson)
  //       );
  //     }
  //   })();

  //   return () => {
  //     active = false;
  //   };
  // }, [regionsLoading]);

  // React.useEffect(() => {
  //   if (!regionsOpen) {
  //     setRegionsOptions([]);
  //   }
  // }, [regionsOpen]);

  const [regionsOpenBilling, setRegionsOpenBilling] = React.useState(false);
  const [regionsOptionsBilling, setRegionsOptionsBilling] = React.useState<readonly RegionDataType[]>([]);
  const regionsLoadingBilling = regionsOpenBilling && regionsOptionsBilling.length === 0;

  // React.useEffect(() => {
  //   let active = true;

  //   if (!regionsLoadingBilling) {
  //     return undefined;
  //   }

  //   (async () => {
  //     if (active) {
  //       setRegionsOptionsBilling(
  //         getRegions(getValues("bill_country") ? getValues("bill_country").countryShortCode : "", allCountriesjson)
  //       );
  //     }
  //   })();

  //   return () => {
  //     active = false;
  //   };
  // }, [regionsLoadingBilling]);

  // React.useEffect(() => {
  //   if (!regionsOpenBilling) {
  //     setRegionsOptionsBilling([]);
  //   }
  // }, [regionsOpenBilling]);

  let accept_privacy_text = translation?.accept_privacy_text || "I aggree to the data privacy policy.";
  if (translation?.accept_privacy_word && translation?.accept_privacy_url) {
    accept_privacy_text = accept_privacy_text.replace(
      new RegExp(translation?.accept_privacy_word, "gi"),
      "<a target='_blank' href=" + translation?.accept_privacy_url + ">" + translation?.accept_privacy_word + "</a>"
    );
  }
  if (translation?.accept_cancellation_word && translation?.accept_cancellation_url) {
    accept_privacy_text = accept_privacy_text.replace(
      new RegExp(translation?.accept_cancellation_word, "gi"),
      "<a target='_blank' href=" +
        translation?.accept_cancellation_url +
        ">" +
        translation?.accept_cancellation_word +
        "</a>"
    );
  }
  if (translation?.accept_terms_word && translation?.accept_terms_url) {
    accept_privacy_text = accept_privacy_text.replace(
      new RegExp(translation?.accept_terms_word, "gi"),
      "<a target='_blank' href=" + translation?.accept_terms_url + ">" + translation?.accept_terms_word + "</a>"
    );
  }

  return (
    <React.Fragment>
      <CheckoutTitle>{translation?.shipping_address || "Shipping Details"}</CheckoutTitle>
      <Grid container spacing={3}>
        {/* <Grid item xs={12} sm={12}>
          <Controller
            name="name"
            defaultValue={state.data.customer.name ? state.data.customer.name : ""}
            rules={{ required: true, minLength: 2 }}
            control={control}
            render={({ field: { ref, onChange, value } }) => (
              <RedditTextField
                variant="filled"
                fullWidth
                required
                label={translation?.name || "Name"}
                autoComplete="name"
                error={!!errors.name}
                helperText={
                  errors.name?.type === "required" ? translation?.name_required || "Name required" : null
                }
                inputRef={ref}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <Controller
            name="first_name"
            defaultValue={
              typeof state.data.customer.metadata === "object"
                ? ""
                : JSON.parse(state.data.customer.metadata)["first_name"]
                ? JSON.parse(state.data.customer.metadata)["first_name"]
                : ""
            }
            rules={{ required: true, minLength: 2 }}
            control={control}
            render={({ field: { ref, onChange, value } }) => (
              <RedditTextField
                variant="filled"
                fullWidth
                required
                label={translation?.first_name || "First Name"}
                autoComplete="given-name"
                error={!!errors.first_name}
                helperText={
                  errors.first_name?.type === "required"
                    ? translation?.first_name_required || "First name required"
                    : null
                }
                inputRef={ref}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="last_name"
            defaultValue={
              typeof state.data.customer.metadata === "object"
                ? ""
                : JSON.parse(state.data.customer.metadata)["last_name"]
                ? JSON.parse(state.data.customer.metadata)["last_name"]
                : ""
            }
            rules={{ required: true, minLength: 2 }}
            control={control}
            render={({ field: { ref, onChange, value } }) => (
              <RedditTextField
                variant="filled"
                fullWidth
                required
                label={translation?.last_name || "Last Name"}
                autoComplete="family-name"
                error={!!errors.last_name}
                helperText={
                  errors.last_name?.type === "required" ? translation?.last_name_required || "Last name required" : null
                }
                inputRef={ref}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Controller
            name="company_name"
            defaultValue={
              typeof state.data.customer.metadata === "object"
                ? ""
                : JSON.parse(state.data.customer.metadata)["company_name"]
                ? JSON.parse(state.data.customer.metadata)["company_name"]
                : ""
            }
            rules={{}}
            control={control}
            render={({ field: { ref, onChange, value } }) => (
              <RedditTextField
                variant="filled"
                fullWidth
                // required
                label={translation?.company_name || "Company Name"}
                autoComplete="organization"
                error={!!errors.name}
                helperText={
                  errors.companyName?.type === "required" ? translation?.required_error_text || "Required" : null
                }
                inputRef={ref}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="email"
            defaultValue={state.data.customer.email ? state.data.customer.email : ""}
            rules={{
              required: true,
              minLength: 4,
              pattern: GetEmailRegex(),
            }}
            control={control}
            render={({ field: { ref, onChange, value } }) => (
              <RedditTextField
                variant="filled"
                fullWidth
                label={translation?.email_address || "Email"}
                autoComplete="email"
                error={!!errors.email}
                helperText={
                  errors.email?.type === "required"
                    ? translation?.email_required || "Email required"
                    : errors.email?.type === "pattern"
                    ? translation?.email_invalid || "Invalid Email address"
                    : null
                }
                required
                inputRef={ref}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="phone"
            defaultValue={state.data.customer.phone ? state.data.customer.phone : ""}
            rules={{ minLength: 4 }}
            control={control}
            render={({ field: { ref, onChange, value } }) => (
              <RedditPhoneField
                variant="filled"
                fullWidth
                label={translation?.phone_number || "Phone"}
                defaultCountry={(userTimeZoneCountryCode || "at").toLowerCase()}
                autoComplete="tel"
                error={!!errors.phone}
                helperText={
                  errors.phone?.type === "required" ? translation?.phone_number_required || "Phone required" : null
                }
                // inputRef={ref}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="address1"
            defaultValue={state.data.customer.shipping.address.line1 ? state.data.customer.shipping.address.line1 : ""}
            rules={{ required: true }}
            control={control}
            render={({ field: { ref, onChange, value } }) => (
              <RedditTextField
                variant="filled"
                fullWidth
                required
                label={translation?.address_line_1 || "Address line 1"}
                autoComplete="shipping address-line1"
                error={!!errors.address1}
                helperText={
                  errors.address1?.type === "required" || errors.address1?.type === "minLength"
                    ? translation?.address_required || "Address required"
                    : null
                }
                inputRef={ref}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="address2"
            defaultValue={state.data.customer.shipping.address.line2 ? state.data.customer.shipping.address.line2 : ""}
            rules={{}}
            control={control}
            render={({ field: { ref, onChange, value } }) => (
              <RedditTextField
                variant="filled"
                fullWidth
                label={translation?.address_line_2 || "Address line 2"}
                autoComplete="shipping address-line2"
                error={!!errors.address2}
                inputRef={ref}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="zip"
            defaultValue={
              state.data.customer.shipping.address.postalCode ? state.data.customer.shipping.address.postalCode : ""
            }
            rules={{ required: true }}
            control={control}
            render={({ field: { ref, onChange, value } }) => (
              <RedditTextField
                variant="filled"
                fullWidth
                required
                label={translation?.postal_code || "Zip / Postal code"}
                autoComplete="shipping postal-code"
                error={!!errors.zip}
                helperText={
                  errors.zip?.type === "required"
                    ? translation?.postal_code_required || "Zip / Postal code required"
                    : null
                }
                inputRef={ref}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Controller
            name="city"
            defaultValue={state.data.customer.shipping.address.city ? state.data.customer.shipping.address.city : ""}
            rules={{ required: true }}
            control={control}
            render={({ field: { ref, onChange, value } }) => (
              <RedditTextField
                variant="filled"
                fullWidth
                required
                label={translation?.city || "City"}
                autoComplete="shipping address-level2"
                error={!!errors.city}
                helperText={errors.city?.type === "required" ? translation?.city_required || "City required" : null}
                inputRef={ref}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={regionsOpen ? 6 : 12}>
          <Controller
            control={control}
            name="country"
            defaultValue={
              state.data.customer.shipping.address.country
                ? state.data.customer.shipping.address.country
                : allowed_shipping_countries.includes(userTimeZoneCountryCode)
                ? userTimeZoneCountryCode // ? getCountry(state.data.customer.shipping.address.country)
                : ""
            }
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, name, ref, ...field } }) => (
              <CountrySelectMaterial
                // selectedCountry={selectedCountry}
                // selectedCountry={selectedCountry}
                // onSelect={setSelectedCountry}
                // {...field}
                value={value}
                required
                fullWidth
                error={!!errors?.country}
                helperText={
                  errors.country?.type === "required" ? translation?.country_required || "Country required" : null
                }
                label={translation?.select_country || "Select your country"}
                inputRef={ref}
                onChange={onChange}
                // onChange={(e: any, v: string) => {
                //   setRegionsOptions([]);
                //   setValue("state", null);
                //   onChange(v);
                // }}
              />
              //     <Autocomplete
              //       {...field}
              //       onChange={(e, v) => {
              //         setRegionsOptions([]);
              //         setValue("state", null);
              //         onChange(v);
              //       }}
              //       fullWidth
              //       // freeSolo
              //       autoSelect={true}
              //       // onInputChange={(e, value, reason) => {
              //       //   console.log("value", value, " Reason", reason);
              //       //   const filtered_country = getCountry(value, allCountriesjson);
              //       //   if (filtered_country !== null) {
              //       //     setValue("counrty", filtered_country);
              //       //   }
              //       // }}
              //       options={allCountriesjson}
              //       filterOptions={countryFilterOptions}
              //       isOptionEqualToValue={(option: CountryDataType | string, value) => {
              //         return typeof option === "string"
              //           ? option === value.countryShortCode || option === value.countryName
              //           : option.countryShortCode === value.countryShortCode || option.countryName === value.countryName;
              //       }}
              //       renderOption={(props: any, option: CountryDataType) => (
              //         <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
              //           <img
              //             loading="lazy"
              //             width="20"
              //             src={`https://flagcdn.com/w20/${option.countryShortCode.toLowerCase()}.png`}
              //             srcSet={`https://flagcdn.com/w40/${option.countryShortCode.toLowerCase()}.png 2x`}
              //             alt=""
              //           />
              //           {option.countryName} ({option.countryShortCode})
              //         </Box>
              //       )}
              //       getOptionLabel={(option: CountryDataType | string) =>
              //         typeof option === "string" ? option : option.countryName
              //       }
              //       renderInput={(params) => (
              //         <RedditTextField
              //           {...params}
              //           inputRef={ref}
              //           required
              //           error={!!errors?.country}
              //           helperText={errors?.country?.message}
              //           label={translation?.select_country || "Select your country"}
              //           onAnimationStart={onAnimationStart}
              //           // autoComplete="shipping country"
              //           variant="filled"
              //           InputProps={{
              //             ...params.InputProps,
              //             autoComplete: "new-password",
              //             // startAdornment: <InputAdornment position="start"></InputAdornment>,
              //           }}
              //         />
              //       )}
              //     />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ display: "none" }}>
          <Controller
            control={control}
            name="state"
            defaultValue={
              state.data.customer.shipping.address.state ? getRegion(state.data.customer.shipping.address.state) : null
            }
            // defaultValue={null}
            rules={
              {
                // required: true
              }
            }
            render={({ field: { onChange, onBlur, value, name, ref, ...field } }) => (
              <StateSelectMaterial
                selectedCountry={selectedCountry}
                selectedState={selectedState}
                regionsOptions={regionsOptions}
                onSelect={setSelectedState}
                required
                fullWidth
                error={!!errors?.state}
                helperText={errors?.state?.message}
                label={translation?.state || "State/Province/Region"}
                inputRef={ref}
                onChange={onChange}
              ></StateSelectMaterial>
              // <Autocomplete
              //   {...field}
              //   onChange={(e, v) => onChange(v)}
              //   fullWidth
              //   open={regionsOpen}
              //   onOpen={() => {
              //     setRegionsOpen(true);
              //   }}
              //   onClose={() => {
              //     setRegionsOpen(false);
              //   }}
              //   loading={regionsLoading}
              //   options={regionsOptions}
              //   filterOptions={regionFilterOptions}
              //   isOptionEqualToValue={(option: RegionDataType, value) => option.name === value.name}
              //   renderOption={(props: any, option: RegionDataType) => (
              //     <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
              //       {option.name}
              //     </Box>
              //   )}
              //   getOptionLabel={(option: RegionDataType) => option.name}
              //   renderInput={(params) => (
              //     <RedditTextField
              //       {...params}
              //       inputRef={ref}
              //       required
              //       error={!!errors?.state}
              //       helperText={errors?.state?.message}
              //       label={translation?.state || "State/Province/Region"}
              //       // autoComplete="shipping address-level1"
              //       variant="filled"
              //       InputProps={{
              //         ...params.InputProps,
              //         autoComplete: "new-password",
              //         // startAdornment: <InputAdornment position="start"></InputAdornment>,
              //       }}
              //     />
              //   )}
              // />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="billing_different"
            defaultValue={false}
            rules={{}}
            control={control}
            render={({ field: props }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<CheckBoxUnCheckedIcon />}
                    checkedIcon={<CheckBoxCheckedIcon />}
                    {...props}
                    checked={props.value}
                    onChange={(e) => {
                      props.onChange(e.target.checked);
                      setBillingOpen(getValues("billing_different"));
                    }}
                  />
                }
                label={translation?.billing_different || "I am using different billing address"}
                sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: 400, color: theme.palette.primary.main }}
              />
            )}
          />
        </Grid>
      </Grid>
      {billingOpen ? (
        <>
          <CheckoutTitle sx={{ mt: "24px" }}>{translation?.billing_address || "Billing address"}</CheckoutTitle>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name="bill_address1"
                defaultValue={state.data.customer.address.line1 ? state.data.customer.address.line1 : ""}
                rules={{ required: billingOpen }}
                control={control}
                render={({ field: { ref, onChange, value } }) => (
                  <RedditTextField
                    variant="filled"
                    fullWidth
                    required
                    label={translation?.address_line_1 || "Address line 1"}
                    autoComplete="billing address-line1"
                    error={!!errors.bill_address1}
                    helperText={
                      errors.bill_address1?.type === "required"
                        ? translation?.address_required || "Address required"
                        : null
                    }
                    inputRef={ref}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="bill_address2"
                defaultValue={state.data.customer.address.line2 ? state.data.customer.address.line2 : ""}
                rules={{}}
                control={control}
                render={({ field: { ref, onChange, value } }) => (
                  <RedditTextField
                    variant="filled"
                    fullWidth
                    label={translation?.address_line_2 || "Address line 2"}
                    autoComplete="billing address-line2"
                    error={!!errors.bill_address2}
                    inputRef={ref}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="bill_zip"
                defaultValue={state.data.customer.address.postalCode ? state.data.customer.address.postalCode : ""}
                rules={{ required: billingOpen }}
                control={control}
                render={({ field: { ref, onChange, value } }) => (
                  <RedditTextField
                    variant="filled"
                    fullWidth
                    required
                    label={translation?.postal_code || "Zip / Postal code"}
                    autoComplete="billing postal-code"
                    error={!!errors.bill_zip}
                    helperText={
                      errors.bill_zip?.type === "required"
                        ? translation?.postal_code_required || "Zip / Postal code Required"
                        : null
                    }
                    inputRef={ref}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Controller
                name="bill_city"
                defaultValue={state.data.customer.address.city ? state.data.customer.address.city : ""}
                rules={{ required: billingOpen }}
                control={control}
                render={({ field: { ref, onChange, value } }) => (
                  <RedditTextField
                    variant="filled"
                    fullWidth
                    required
                    label={translation?.city || "City"}
                    autoComplete="billing address-level2"
                    error={!!errors.bill_city}
                    helperText={
                      errors.bill_city?.type === "required" ? translation?.city_required || "City required" : null
                    }
                    inputRef={ref}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={regionsOpenBilling ? 6 : 12}>
              <Controller
                control={control}
                name="bill_country"
                defaultValue={
                  state.data.customer.address.country ? state.data.customer.address.country : ""
                  // state.data.customer.address.country ? getCountry(state.data.customer.address.country) : ""
                }
                rules={{ required: billingOpen }}
                render={({ field: { onChange, onBlur, value, name, ref, ...field } }) => (
                  <CountrySelectMaterial
                    value={value}
                    required
                    fullWidth
                    error={!!errors?.bill_country}
                    helperText={
                      errors.bill_country?.type === "required"
                        ? translation?.country_required || "Country required"
                        : null
                    }
                    label={translation?.select_country || "Select your country"}
                    inputRef={ref}
                    onChange={onChange}
                  />
                  // <Autocomplete
                  //   {...field}
                  //   onChange={(e, v) => {
                  //     setRegionsOptionsBilling([]);
                  //     setValue("bill_state", null);
                  //     onChange(v);
                  //   }}
                  //   fullWidth
                  //   options={allCountriesjson}
                  //   filterOptions={countryFilterOptions}
                  //   isOptionEqualToValue={(option: CountryDataType | string, value) => {
                  //     return typeof option === "string"
                  //       ? option === value.countryShortCode || option === value.countryName
                  //       : option.countryShortCode === value.countryShortCode ||
                  //           option.countryName === value.countryName;
                  //   }}
                  //   renderOption={(props: any, option: CountryDataType) => (
                  //     <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                  //       <img
                  //         loading="lazy"
                  //         width="20"
                  //         src={`https://flagcdn.com/w20/${option.countryShortCode.toLowerCase()}.png`}
                  //         srcSet={`https://flagcdn.com/w40/${option.countryShortCode.toLowerCase()}.png 2x`}
                  //         alt=""
                  //       />
                  //       {option.countryName} ({option.countryShortCode})
                  //     </Box>
                  //   )}
                  //   getOptionLabel={(option: CountryDataType | string) =>
                  //     typeof option === "string" ? option : option.countryName
                  //   }
                  //   renderInput={(params) => (
                  //     <RedditTextField
                  //       {...params}
                  //       inputRef={ref}
                  //       required
                  //       error={!!errors?.bill_country}
                  //       helperText={errors.bill_country?.type === "required" ? "Country required" : null}
                  //       label={translation?.select_country || "Select your country"}
                  //       // autoComplete="billing country"
                  //       variant="filled"
                  //       InputProps={{
                  //         ...params.InputProps,
                  //         autoComplete: "new-password",
                  //         // startAdornment: <InputAdornment position="start"></InputAdornment>,
                  //       }}
                  //     />
                  //   )}
                  // />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ display: "none" }}>
              <Controller
                control={control}
                name="bill_state"
                defaultValue={state.data.customer.address.state ? getRegion(state.data.customer.address.state) : null}
                rules={
                  {
                    // required: billingOpen
                  }
                }
                render={({ field: { value, name, ref, onChange, ...field } }) => (
                  <StateSelectMaterial
                    selectedCountry={selectedCountry}
                    selectedState={selectedState}
                    regionsOptions={regionsOptions}
                    onSelect={setSelectedState}
                    required
                    fullWidth
                    error={!!errors?.bill_state}
                    helperText={errors.bill_state?.type === "required" ? errors?.state?.message : null}
                    label={translation?.state || "State/Province/Region"}
                    inputRef={ref}
                    onChange={onChange}
                  ></StateSelectMaterial>
                  // <Autocomplete
                  //   {...field}
                  //   onChange={(e, v) => onChange(v)}
                  //   fullWidth
                  //   open={regionsOpenBilling}
                  //   onOpen={() => {
                  //     setRegionsOpenBilling(true);
                  //   }}
                  //   onClose={() => {
                  //     setRegionsOpenBilling(false);
                  //   }}
                  //   loading={regionsLoadingBilling}
                  //   options={regionsOptionsBilling}
                  //   filterOptions={regionFilterOptions}
                  //   isOptionEqualToValue={(option: RegionDataType, value) => option.name === value.name}
                  //   renderOption={(props: any, option: RegionDataType) => (
                  //     <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                  //       {option.name}
                  //     </Box>
                  //   )}
                  //   getOptionLabel={(option: RegionDataType) => option.name}
                  //   renderInput={(params) => (
                  //     <RedditTextField
                  //       {...params}
                  //       inputRef={ref}
                  //       required
                  //       error={!!errors.bill_state}
                  //       helperText={errors.bill_state?.type === "required" ? "State required" : null}
                  //       label={translation?.state || "State/Province/Region"}
                  //       // autoComplete="billing address-level1"
                  //       variant="filled"
                  //       InputProps={{
                  //         ...params.InputProps,
                  //         autoComplete: "new-password",
                  //         // startAdornment: <InputAdornment position="start"></InputAdornment>,
                  //       }}
                  //     />
                  //   )}
                  // />
                )}
              />
            </Grid>
          </Grid>
          <Typography variant="body1" sx={{ color: "#8193A8", fontSize: "12px", marginTop: "16px" }}></Typography>
        </>
      ) : (
        ""
      )}
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Controller
            name="accept_privacy"
            defaultValue={false}
            rules={{ required: true }}
            control={control}
            render={({ field: props }) => (
              <FormControl required error={!!errors.accept_privacy} component="fieldset" variant="standard">
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<CheckBoxUnCheckedIcon colorBorder={!!errors.accept_privacy ? "#ef5350" : undefined} />}
                      checkedIcon={<CheckBoxCheckedIcon />}
                      required
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  }
                  label={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: accept_privacy_text,
                      }}
                    />
                  }
                  ref={props.ref}
                  sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: 400, color: theme.palette.primary.main }}
                />
                <FormHelperText>
                  {errors.accept_privacy?.type === "required"
                    ? translation?.accept_required || "Accept required"
                    : null}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Controller
            name="subscribe_newsletter"
            defaultValue={state.data.isNewsletterChecked ? true : false}
            rules={{}}
            control={control}
            render={({ field: props }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<CheckBoxUnCheckedIcon />}
                    checkedIcon={<CheckBoxCheckedIcon />}
                    {...props}
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                }
                label={translation?.newsletter_sign_me_up || "Sign me up for the playbrush newsletter"}
                sx={{ fontSize: "16px", lineHeight: "24px", fontWeight: 400, color: theme.palette.primary.main }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Typography variant="body1" sx={{ color: "#8193A8", fontSize: "12px" }}>
        {translation?.note_required_error_note || "- Fields marked with * are mandatory"}
      </Typography>
      <Typography variant="body1" sx={{ color: "#8193A8", fontSize: "12px" }}>
        {translation?.note_phone_number_usage || "- We will only use your phone number for delivery purposes"}
      </Typography>
      {cartData && <CartList cart={cartData} listType={"MARKETING"} translation={translation} />}
    </React.Fragment>
  );
};

export default Customer;
