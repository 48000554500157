import * as React from "react";
import clsx from "clsx";
import { styled, css } from "@mui/system";
import { Modal as BaseModal } from "@mui/base/Modal";
import { Button } from "@mui/base/Button";
import CloseIcon from "@mui/icons-material/Close";
import Chip from "@mui/material/Chip";
import { Box, colors } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { CheckoutStatusEnum } from "../screens/checkout";

type Props = {
  translations: GQL_AEM.CheckoutTranslationModels | null;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  status: CheckoutStatusEnum;
  title: string;
  message: string;
  triggerButtonShow?: boolean;
  closeFunction?: () => void;
};

export default function MessageConfirmModal(props: Props) {
  const translations = props.translations;
  const open = props.open;
  const setOpen = props.setOpen;
  const status = props.status;
  const title = props.title;
  const message = props.message;
  let navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    if (props.status === CheckoutStatusEnum.EXPIRED) {
      navigate("/");
      location.reload();
    }
    if (props.status === CheckoutStatusEnum.FAILED) {
      navigate("/checkout");
      location.reload();
    }
    if (props.closeFunction) {
      props.closeFunction();
    }
  };

  return (
    <div>
      {props.triggerButtonShow && <TriggerButton onClick={handleOpen}>Open modal</TriggerButton>}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        slots={{ backdrop: StyledBackdrop }}
      >
        <ModalContent sx={style}>
          <IconWrapper>
            <CloseIcon />
          </IconWrapper>
          <h2 id="parent-modal-title" className="modal-title">
            Error
          </h2>
          <h2 id="parent-modal-title" className="modal-title">
            {title}
          </h2>
          <p id="parent-modal-description" className="modal-description">
            {message}
          </p>
          <ModalButton onClick={handleClose}>OK</ModalButton>
        </ModalContent>
      </Modal>
    </div>
  );
}

const Backdrop = React.forwardRef<HTMLDivElement, { open?: boolean; className: string }>((props, ref) => {
  const { open, className, ...other } = props;
  return <div className={clsx({ "base-Backdrop-open": open }, className)} ref={ref} {...other} />;
});

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
};

const ModalContent = styled("div")(
  ({ theme }) => css`
    /* font-family: "Gotham";
    font-weight: 500; */
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === "dark" ? colors.grey[900] : "#fff"};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === "dark" ? colors.grey[700] : colors.grey[200]};
    box-shadow: 0 4px 12px ${theme.palette.mode === "dark" ? "rgb(0 0 0 / 0.5)" : "rgb(0 0 0 / 0.2)"};
    padding: 24px;
    color: ${theme.palette.mode === "dark" ? colors.grey[50] : colors.grey[900]};
    outline: none;

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
      text-align: center;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[800]};
      margin-bottom: 4px;
      text-align: center;
      font-size: 12px;
    }
  `
);

const IconWrapper = styled(Box)(
  ({ theme }) => css`
    background-color: #ffcaca;
    margin: auto;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 30px;
    color: red;
  `
);

const TriggerButton = styled(Button)(
  ({ theme }) => css`
    /* font-family: "Gotham";
    font-weight: 600; */
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 8px 16px;
    border-radius: 8px;
    transition: all 150ms ease;
    cursor: pointer;
    background: ${theme.palette.mode === "dark" ? colors.grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? colors.grey[700] : colors.grey[200]};
    color: ${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[900]};
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

    &:hover {
      background: ${theme.palette.mode === "dark" ? colors.grey[800] : colors.grey[50]};
      border-color: ${theme.palette.mode === "dark" ? colors.grey[600] : colors.grey[300]};
    }

    &:active {
      background: ${theme.palette.mode === "dark" ? colors.grey[700] : colors.grey[100]};
    }

    &:focus-visible {
      box-shadow: 0 0 0 4px ${theme.palette.mode === "dark" ? colors.blue[300] : colors.blue[200]};
      outline: none;
    }
  `
);

const ModalButton = styled(Button)(
  ({ theme }) => css`
    /* font-family: "Gotham";
    font-weight: 600; */
    font-size: 0.875rem;
    line-height: 1.5;
    background-color: ${colors.red[500]};
    padding: 8px 16px;
    border-radius: 8px;
    color: white;
    transition: all 150ms ease;
    cursor: pointer;
    border: 1px solid ${colors.red[500]};
    box-shadow: 0 2px 1px ${theme.palette.mode === "dark" ? "rgba(0, 0, 0, 0.5)" : "rgba(45, 45, 60, 0.2)"};
    &:hover {
      background-color: ${colors.red[600]};
    }

    &:active {
      background-color: ${colors.red[700]};
      box-shadow: none;
    }

    &:focus-visible {
      box-shadow: 0 0 0 4px ${theme.palette.mode === "dark" ? colors.red[300] : colors.red[200]};
      outline: none;
    }

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
      box-shadow: none;

      &:hover {
        background-color: ${colors.red[500]};
      }
    }
  `
);
