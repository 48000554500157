import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useStateMachine } from "little-state-machine";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { addPageViewTrackingEvent, purchaseTrackingEvent } from "../components/trackingEvents";
import { useCheckoutStatus } from "../hooks/useCheckout";
import { CheckoutStatusEnum } from "./checkout";
import { PAGEVIEW } from "../api";

function timeout(delay: number) {
  return new Promise((res) => setTimeout(res, delay));
}

export default function Complete({
  cartData,
  translation,
  setMessageSnackBarOpen,
  setMessageSnackBar,
  setMessageConfirmModalOpen,
  setMessageConfirmModal,
  setMessageStatusConfirmModal,
  setMessageTitleConfirmModal,
  setMessageConfirmModalCloseFunction,
}: {
  cartData: GQL.CheckoutOpen | GQL.CheckoutUpdate | null;
  translation: GQL_AEM.CheckoutTranslationModels | null;
  setMessageSnackBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setMessageSnackBar: React.Dispatch<React.SetStateAction<string>>;
  setMessageConfirmModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setMessageConfirmModal: React.Dispatch<React.SetStateAction<string>>;
  setMessageStatusConfirmModal: React.Dispatch<React.SetStateAction<CheckoutStatusEnum>>;
  setMessageTitleConfirmModal: React.Dispatch<React.SetStateAction<string>>;
  setMessageConfirmModalCloseFunction: React.Dispatch<React.SetStateAction<void>>;
}) {
  let { checkoutId } = useParams();
  let navigate = useNavigate();
  const { state } = useStateMachine();
  const mutationCheckoutStatus = useCheckoutStatus();
  const theme = useTheme();

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    setCounter(counter + 1);

    addPageViewTrackingEvent(cartData, PAGEVIEW.CHECKOUT_PAYMENT_VALIDATION);
  }, []);
  useEffect(() => {
    if (checkoutId == null || checkoutId === undefined || checkoutId === "") {
      navigate("/checkout");
    } else {
      mutationCheckoutStatus.mutate(
        { checkoutId: checkoutId },
        {
          onSuccess: (data) => {
            if (data.status === CheckoutStatusEnum.COMPLETE) {
              const orderId = data.orderId || "";
              purchaseTrackingEvent(state.data.cartData, orderId);
              if (data.thankYouPage) {
                if (data.thankYouPage.substring(data.thankYouPage.length - 5) !== ".html") {
                  data.thankYouPage = data.thankYouPage.replace(/\/$|$/, "/"); // add end slash
                }
                window.location.replace(data.thankYouPage + "?checkoutId=" + checkoutId);
              } else {
                navigate("/thank_you/" + checkoutId);
              }
            } else if (data.status === CheckoutStatusEnum.OPEN) {
              navigate("/checkout");
            } else if (data.status === CheckoutStatusEnum.FAILED) {
              setMessageTitleConfirmModal(translation?.message_payment_failed || "Payment Failed, please try again!");
              setMessageConfirmModal(data.errorMessage || "");
              setMessageStatusConfirmModal(CheckoutStatusEnum.FAILED);
              setMessageConfirmModalOpen(true);
            } else if (data.status === CheckoutStatusEnum.EXPIRED) {
              setMessageTitleConfirmModal(translation?.expired_session || "Expired session");
              setMessageConfirmModal(translation?.message_payment_expired || "This payment session has expired!");
              setMessageStatusConfirmModal(CheckoutStatusEnum.EXPIRED);
              setMessageConfirmModalOpen(true);
            } else {
              if (data.orderId == null && counter > 20) {
                setMessageSnackBar(
                  translation?.message_this_takes_too_long ||
                    "This taking too long. It seems we having trouble processing your order! Please wait for the order confirmation email."
                );
                setMessageSnackBarOpen(true);
              }
              setTimeout(() => {
                setCounter(counter + 1);
              }, 1000);
            }
          },
          onError: () => {
            setTimeout(() => {
              setCounter(counter + 1);
            }, 1000);
            // TODO error
          },
        }
      );
    }
  }, [counter]);

  return (
    <Box sx={{ mb: 4 }}>
      <Typography
        variant="h4"
        align="center"
        sx={{
          color: theme.palette.primary.main,
        }}
      >
        {translation?.payment_is_processing || "Payment is processing"}
      </Typography>
      <React.Fragment>
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            minHeight: "300px",
          }}
        >
          <CircularProgress />
        </Box>
      </React.Fragment>
    </Box>
  );
}
