import { Box, Typography, IconButton, CardMedia } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";
import { styled, StyledEngineProvider, useTheme, Theme } from "@mui/material/styles";
import Currency from "./currency";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      maxWidth: 345,
    },
    media: {
      height: 140,
      "&:hover": {
        transform: "scale(1.5)",
      },
    },
    productImage: {
      maxWidth: 100,
      maxHeight: 100,
      minWidth: 80,
      backgroundSize: "contain",
      objectFit: "contain",
      borderRadius: "16px",
      background: "#e5e5e5",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        maxHeight: 80,
      },
    },
    productImageSmall: {
      minWidth: 60,
      maxWidth: 60,
      maxHeight: 80,
      backgroundSize: "contain",
      objectFit: "contain",
      borderRadius: "16px",
      background: "#e5e5e5",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        maxHeight: 80,
      },
    },
    productImageEmpty: {
      minWidth: 100,
      maxWidth: 100,
      maxHeight: 100,
      borderRadius: "16px",
      backgroundColor: "#dfdfdf",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        maxHeight: 60,
        maxWidth: 60,
      },
    },
    productImageEmptySmall: {
      minWidth: 60,
      minHeight: 60,
      maxWidth: 60,
      maxHeight: 80,
      borderRadius: "16px",
      backgroundColor: "#dfdfdf",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
    productContentLineInnerColText: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingRight: 16,
      paddingLeft: 16,
      flexGrow: 1,
    },
    productContentLineInnerColTextColumn: {
      display: "flex",
      flexDirection: "column",
      paddingRight: 16,
      paddingLeft: 16,
      justifyContent: "center",
      flexGrow: 1,
    },
    productDescription: {
      marginLeft: "36px",
    },
    productLine: {
      marginBottom: "14px",
    },
  };
});

const MainTitle = styled("h4")(({ theme }) => ({
  margin: 0,
  marginTop: "20px",
  fontWeight: 400,
  fontSize: "20px",
  fontFamily: "Gotham",
  color: theme.palette.primary.main,
  [theme.breakpoints.down("sm")]: {
    fontSize: "18px",
  },
}));
const ItemsCount = styled("p")(({ theme }) => ({
  margin: 0,
  padding: 0,
  marginTop: "20px",
  color: theme.palette.primary.main,
  fontFamily: "Gotham",
  fontWeight: 400,
  fontSize: "18px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
}));
const LineTitle = styled("span")(({ theme }) => ({
  margin: 0,
  padding: 0,
  color: theme.palette.primary.main,
  fontFamily: "Gotham",
  fontWeight: 400,
  fontSize: "24px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
  },
}));
const ProductName = styled("div")(({ theme }) => ({
  ...theme.typography.h5,
  color: theme.palette.primary.main,
  fontFamily: "Gotham",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "24px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));
const QuantityBox = styled("div")(({ theme }) => ({
  width: "24px",
  height: "24px",
  boxShadow: "0px 2px 8px  rgba(0, 0, 0, 0.1)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  borderRadius: "4px",
  marginRight: "12px",
  minWidth: "24px",
  maxWidth: "24px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));
const ProductNameSmall = styled("span")(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.primary.main,
  fontFamily: "Gotham",
  fontWeight: 700,
  fontSize: "12px",
  lineHeight: "12px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
  },
}));
const ProductDescription = styled("div")(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.primary.main,
  fontFamily: "Gotham",
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "14px",
  marginTop: "6px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
  },
}));
const QuantityText = styled("span")(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.primary.main,
  fontFamily: "Gotham",
  fontWeight: 700,
  fontSize: "12px",
  lineHeight: "24px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));
const PriceUVPText = styled("span")(({ theme }) => ({
  fontWeight: 400,
  opacity: 0.4,
  fontSize: "14px",
  textDecoration: "none",
  fontFamily: "Gotham",
  color: theme.palette.primary.main,
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));
const PriceCurrentText = styled("span")(({ theme }) => ({
  fontWeight: 700,
  fontSize: "18px",
  fontFamily: "Gotham",
  color: theme.palette.primary.main,
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
}));
const PriceCrossedText = styled("span")(({ theme }) => ({
  fontWeight: 400,
  opacity: 0.4,
  textDecoration: "line-through",
  fontSize: "14px",
  fontFamily: "Gotham",
  color: theme.palette.primary.main,
  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
  },
}));
const PriceLaterText = styled("span")(({ theme }) => ({
  fontWeight: 400,
  fontSize: "12px",
  fontFamily: "Gotham",
  paddingRight: "3px",
  textAlign: "right",
  color: theme.palette.primary.main,
  [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
  },
}));
const PriceLaterTextBold = styled("span")(({ theme }) => ({
  fontWeight: 700,
  fontSize: "12px",
  fontFamily: "Gotham",
  paddingRight: "3px",
  textAlign: "center",
  color: theme.palette.primary.main,
  [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
  },
}));
const BoxRowColumn = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  minWidth: "160px",
  justifyContent: "flex-end",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-end",
  },
}));

const GrayLine = styled("div")(({ theme }) => ({
  borderBottom: "1px solid",
  borderColor: theme.palette.mode === "dark" ? "#CDD4DC" : "#CDD4DC",
  margin: "16px 0px",
}));
const GrayBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#F8F8F8",
  border: "1px solid",
  borderColor: theme.palette.mode === "dark" ? "#444d58" : "#CDD4DC",
  padding: "32px",
  borderRadius: "16px",
  fontFamily: "Gotham",
  color: theme.palette.primary.main,
}));

export const concatSubscriptionHeading = (
  lineItem: GQL.CartLineItem,
  translations?: GQL_AEM.CheckoutTranslationModels | null
) => {
  // const {
  //     group_title_subscription_abo,
  //     group_title_subscription_game,
  //     group_title_subscription_brush_head
  // } = translations.checkout; // Checkout translations

  const games = lineItem?.lineContent?.find(
    (content) => content?.tags?.includes("subscription") && content?.tags?.includes("games_sub")
  );

  const brushheads = lineItem?.lineContent?.find(
    (content) => content?.tags?.includes("subscription") && content?.tags?.includes("brush_heads")
  );

  return `${translations?.group_title_subscription_abo}${games || brushheads ? ": " : ""}${
    games ? translations?.group_title_subscription_game : ""
  }${games && brushheads ? " & " : ""}${brushheads ? translations?.group_title_subscription_brush_head : ""}`;
};

type Props = {
  cart: GQL.Cart | GQL.CheckoutOpen | GQL.CheckoutUpdate;
  listType?: "DEV" | "ORDER" | "MARKETING";
  onDeleteLine?: (record: any) => void;
  translation?: GQL_AEM.CheckoutTranslationModels | null;
};

const CartList: React.FC<Props> = (props: Props) => {
  const theme = useTheme();
  const { classes } = useStyles();
  const cart = props.cart;
  const listType = props.listType !== undefined ? props.listType : "DEV";
  const translation = props.translation;

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <MainTitle>{translation?.order_overview || "Order overview"}</MainTitle>
        <ItemsCount>
          ({cart.lineItems && cart.lineItems.length} {translation?.items || "items"})
        </ItemsCount>
      </Box>
      <GrayLine />
      {cart.lineItems!.length > 0 ? (
        cart.lineItems!.map((lineItem) => {
          return (
            <Box key={lineItem?.lineId}>
              {listType === "DEV" ? <Box>{lineItem?.lineId}</Box> : <></>}
              {lineItem?.lineContent &&
                lineItem?.lineContent.length === 0 &&
                lineItem?.tags &&
                lineItem?.tags.includes("toothbrush") && (
                  <ProductName>
                    {translation?.group_title_brush}
                    {/* {lineItem?.tags.includes("uvp") && translation?.tag_title_uvp} */}
                  </ProductName>
                )}
              {lineItem?.lineContent &&
                lineItem?.lineContent.length === 0 &&
                lineItem?.tags &&
                (lineItem?.tags.includes("brush_heads") || lineItem?.tags.includes("brushheads")) && (
                  <ProductName>{translation?.group_title_brush_head}</ProductName>
                )}
              {lineItem?.lineContent && lineItem?.lineContent.length > 0 && (
                <ProductName>
                  {listType === "DEV" && lineItem?.productName}
                  {listType === "ORDER" && lineItem?.productNameOrder}
                  {listType === "MARKETING" && lineItem?.productNameMarketing}
                </ProductName>
              )}
              <Box sx={{ display: "flex", margin: "24px 0px 32px 0px" }}>
                {lineItem?.lineContent && lineItem?.lineContent.length === 0 && lineItem?.image && (
                  <CardMedia
                    component="img"
                    className={classes.productImage}
                    image={lineItem?.image}
                    alt="product image"
                  />
                )}
                {lineItem?.lineContent && lineItem?.lineContent.length === 0 && !lineItem?.image && (
                  <div className="productImageEmpty" />
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    paddingLeft: "16px",
                    paddingRight: "16px",
                  }}
                >
                  {lineItem && lineItem.lineContent && lineItem.lineContent.length > 0 ? (
                    <>
                      <>
                        {lineItem.lineContent.map((lineContentItem) => {
                          // Brush
                          if (!lineContentItem?.isSubscription) {
                            return (
                              <Box
                                className={classes.productLine}
                                sx={{ display: "flex", flexDirection: "row" }}
                                key={lineContentItem?.lineId}
                              >
                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                  {lineContentItem && lineContentItem?.image && (
                                    <CardMedia
                                      component="img"
                                      className={classes.productImageSmall}
                                      image={lineContentItem?.image}
                                      alt="product image"
                                    />
                                  )}
                                </Box>
                                {!lineContentItem?.image && <div className={classes.productImageEmptySmall} />}
                                <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                  <Box className={classes.productContentLineInnerColText}>
                                    <QuantityBox>
                                      <QuantityText>
                                        {lineContentItem &&
                                          lineContentItem.quantity &&
                                          lineItem.quantity &&
                                          lineContentItem.quantity * lineItem.quantity}
                                        x
                                      </QuantityText>
                                    </QuantityBox>

                                    <ProductNameSmall>
                                      {listType === "DEV" && lineContentItem?.productName}
                                      {listType === "ORDER" && lineContentItem?.productNameOrder}
                                      {listType === "MARKETING" && lineContentItem?.productNameMarketing}
                                    </ProductNameSmall>
                                  </Box>
                                  <Box className={classes.productContentLineInnerColTextColumn}>
                                    <ProductDescription className={classes.productDescription}>
                                      {lineContentItem?.description}
                                    </ProductDescription>
                                    <ProductDescription className={classes.productDescription}>
                                      {listType === "DEV" && lineContentItem?.infoExplainer}
                                    </ProductDescription>
                                    <ProductDescription className={classes.productDescription}>
                                      {listType === "DEV" && lineContentItem?.infoDelivery}
                                    </ProductDescription>
                                    <ProductDescription className={classes.productDescription}>
                                      {listType === "DEV" && lineContentItem?.infoPayment}
                                    </ProductDescription>
                                  </Box>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-end",
                                    pl: 1,
                                    pb: 1,
                                    pr: 1,
                                    pt: 0,
                                    minWidth: "100px",
                                  }}
                                >
                                  {
                                    // Bundle One time prices
                                    cart.currency &&
                                      lineItem.priceAmount !== undefined &&
                                      lineItem.priceAmount !== null &&
                                      lineItem.quantity !== undefined &&
                                      lineItem.quantity !== null && (
                                        <>
                                          {lineContentItem !== null &&
                                            lineContentItem.priceAmount !== undefined &&
                                            lineContentItem.priceAmount !== null &&
                                            lineItem.priceAmountOneTime !== undefined &&
                                            lineItem.priceAmountOneTime !== null && (
                                              <>
                                                {/* {lineContentItem.tags &&
                                                  lineContentItem.priceAmount !== 0 &&
                                                  lineContentItem.tags.includes("toothbrush") && (
                                                    <PriceBrushText>{translation?.brush || "Bursh"} </PriceBrushText>
                                                  )}
                                                {lineContentItem.tags &&
                                                  lineContentItem.priceAmount !== 0 &&
                                                  (lineContentItem.tags.includes("brush_heads") ||
                                                    lineContentItem.tags.includes("brushheads")) && (
                                                    <PriceBrushText>
                                                      {translation?.brush_head || "Bursh head"}
                                                    </PriceBrushText>
                                                  )} */}
                                                <Box>
                                                  {/* {lineContentItem.tags &&
                                                    lineContentItem.priceAmount !==
                                                      lineContentItem.priceDefaultAmount &&
                                                    lineContentItem.priceDefaultAmount !== undefined &&
                                                    lineContentItem.priceDefaultAmount !== null &&
                                                    lineContentItem.tags.includes("uvp") && (
                                                      <PriceUVPText>
                                                        {translation?.tag_title_uvp || "UVP "}
                                                      </PriceUVPText>
                                                    )} */}
                                                  <PriceCrossedText>
                                                    {lineContentItem.priceAmount !==
                                                      lineContentItem.priceDefaultAmount &&
                                                      lineContentItem.priceDefaultAmount !== undefined &&
                                                      lineContentItem.priceDefaultAmount !== null && (
                                                        <Currency
                                                          price={lineContentItem.priceDefaultAmount * lineItem.quantity}
                                                          currency={cart.currency}
                                                        />
                                                      )}
                                                  </PriceCrossedText>
                                                </Box>
                                                <PriceCurrentText>
                                                  {lineContentItem.priceAmount === 0 ? (
                                                    translation?.free || "Free"
                                                  ) : (
                                                    <Currency
                                                      price={lineContentItem.priceAmount * lineItem.quantity}
                                                      currency={cart.currency}
                                                    />
                                                  )}
                                                </PriceCurrentText>
                                              </>
                                            )}
                                        </>
                                      )
                                  }
                                </Box>
                              </Box>
                            );
                          }
                        })}
                      </>
                      <>
                        {
                          //Subacription group title
                          lineItem.isSubscription && (
                            <Box className={classes.productLine}>
                              <Box sx={{ display: "flex", flexDirection: "row" }}>
                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                  {lineItem && lineItem?.image && (
                                    <CardMedia
                                      component="img"
                                      className={classes.productImageSmall}
                                      image={lineItem?.image}
                                      alt="product image"
                                    />
                                  )}
                                </Box>
                                {!lineItem?.image && <div className={classes.productImageEmptySmall} />}
                                <Box className={classes.productContentLineInnerColText}>
                                  <QuantityBox>
                                    <QuantityText>{lineItem.quantity && lineItem.quantity}x</QuantityText>
                                  </QuantityBox>

                                  <ProductNameSmall>
                                    {concatSubscriptionHeading(lineItem, translation)}
                                  </ProductNameSmall>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-end",
                                    justifyContent: "center",
                                    pl: 1,
                                    pb: 1,
                                    pr: 1,
                                    pt: 0,
                                    minWidth: "100px",
                                  }}
                                >
                                  {
                                    // Bundle Recurring prices
                                    cart.currency &&
                                      lineItem.priceAmount !== undefined &&
                                      lineItem.priceAmount !== null &&
                                      lineItem.quantity !== undefined &&
                                      lineItem.quantity !== null && (
                                        <>
                                          {lineItem.priceAmountRecurring !== undefined &&
                                            lineItem.priceAmountRecurring !== null && (
                                              <>
                                                <PriceCurrentText>
                                                  {lineItem.priceAmount === 0 ? (
                                                    translation?.free || "Free"
                                                  ) : (
                                                    <Currency
                                                      price={lineItem.priceAmountRecurring * lineItem.quantity}
                                                      currency={cart.currency}
                                                    />
                                                  )}
                                                </PriceCurrentText>
                                              </>
                                            )}
                                          {lineItem.recurringTrialPeriodDays !== null &&
                                            lineItem.recurringTrialPeriodDays !== undefined &&
                                            lineItem.recurringTrialPeriodDays > 0 && (
                                              <PriceLaterText>
                                                {translation?.subscription_one_month_free || "Subsc. 1 month FREE"}
                                              </PriceLaterText>
                                            )}
                                          {lineItem.priceAmountAfterTrial && lineItem.quantity && (
                                            <>
                                              <BoxRowColumn>
                                                <PriceLaterText> </PriceLaterText>
                                                <PriceLaterText>
                                                  {translation?.then || "then"} {translation?.every || "every"}{" "}
                                                  {lineItem.recurringIntervalCount !== undefined &&
                                                    lineItem.recurringIntervalCount !== null &&
                                                    lineItem.recurringIntervalCount > 1 &&
                                                    lineItem.recurringIntervalCount}{" "}
                                                  {lineItem.recurringInterval === "day" && (translation?.day || "day")}
                                                  {lineItem.recurringInterval === "month" &&
                                                    (translation?.month || "month")}
                                                  {lineItem.recurringInterval === "year" &&
                                                    (translation?.year || "year")}
                                                </PriceLaterText>
                                                <PriceLaterTextBold>
                                                  <Currency
                                                    price={lineItem.priceAmountAfterTrial * lineItem.quantity}
                                                    currency={cart.currency}
                                                  />
                                                </PriceLaterTextBold>
                                              </BoxRowColumn>
                                              <BoxRowColumn></BoxRowColumn>
                                            </>
                                          )}
                                        </>
                                      )
                                  }
                                </Box>
                              </Box>
                            </Box>
                          )
                        }
                      </>
                      <>
                        {lineItem.lineContent.map((lineContentItem) => {
                          //Subacription List
                          if (lineContentItem?.isSubscription) {
                            return (
                              <Box
                                className={classes.productLine}
                                sx={{
                                  marginLeft: "76px",
                                  [theme.breakpoints.down("sm")]: {
                                    marginLeft: "16px",
                                  },
                                }}
                                key={lineContentItem?.lineId}
                              >
                                <Box sx={{ display: "flex", flexDirection: "row" }} key={lineContentItem?.lineId}>
                                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                                    {lineContentItem && lineContentItem?.image && (
                                      <CardMedia
                                        component="img"
                                        className={classes.productImageSmall}
                                        image={lineContentItem?.image}
                                        alt="product image"
                                      />
                                    )}
                                  </Box>
                                  {!lineContentItem?.image && <div className={classes.productImageEmptySmall} />}
                                  <Box
                                    className={classes.productContentLineInnerColTextColumn}
                                    key={lineContentItem?.lineId}
                                  >
                                    {/* <QuantityBox>
                                      <QuantityText>
                                        x
                                        {lineContentItem &&
                                          lineContentItem.quantity &&
                                          lineItem.quantity &&
                                          lineContentItem.quantity * lineItem.quantity}
                                      </QuantityText>
                                    </QuantityBox> */}

                                    <ProductNameSmall>
                                      {listType === "DEV" && lineContentItem?.productName}
                                      {listType === "ORDER" && lineContentItem?.productNameOrder}
                                      {listType === "MARKETING" && lineContentItem?.productNameMarketing}
                                    </ProductNameSmall>
                                    <ProductDescription>{lineContentItem?.description}</ProductDescription>
                                    <ProductDescription>
                                      {listType === "DEV" && lineContentItem?.infoExplainer}
                                    </ProductDescription>
                                    <ProductDescription>
                                      {listType === "DEV" && lineContentItem?.infoDelivery}
                                    </ProductDescription>
                                    <ProductDescription>
                                      {listType === "DEV" && lineContentItem?.infoPayment}
                                    </ProductDescription>
                                  </Box>
                                </Box>
                              </Box>
                            );
                          }
                        })}
                      </>
                    </>
                  ) : (
                    // Single product
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Box sx={{ flexGrow: 1 }}>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          <QuantityBox>
                            <QuantityText>
                              {lineItem && lineItem.quantity && lineItem.quantity && lineItem.quantity}x
                            </QuantityText>
                          </QuantityBox>
                          <ProductName>
                            {listType === "DEV" && lineItem?.productName}
                            {listType === "ORDER" && lineItem?.productNameOrder}
                            {listType === "MARKETING" && lineItem?.productNameMarketing}
                          </ProductName>
                        </Box>
                        <ProductDescription className={classes.productDescription}>
                          {lineItem && lineItem?.description}
                        </ProductDescription>
                        <ProductDescription className={classes.productDescription}>
                          {listType === "DEV" && lineItem && lineItem?.infoExplainer}
                        </ProductDescription>
                        <ProductDescription className={classes.productDescription}>
                          {listType === "DEV" && lineItem && lineItem?.infoDelivery}
                        </ProductDescription>
                        <ProductDescription className={classes.productDescription}>
                          {listType === "DEV" && lineItem && lineItem?.infoPayment}
                        </ProductDescription>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                          minWidth: "100px",
                        }}
                      >
                        {lineItem &&
                          cart.currency &&
                          lineItem.priceAmount !== undefined &&
                          lineItem.priceAmount !== null &&
                          lineItem.quantity !== undefined &&
                          lineItem.quantity !== null && (
                            <>
                              {/* {lineItem.tags && lineItem.priceAmount !== 0 && lineItem.tags.includes("toothbrush") && (
                                <PriceBrushText>
                                  {lineItem.priceAmount !== 0 && (translation?.brush || "Bursh")}
                                </PriceBrushText>
                              )}
                              {lineItem.tags &&
                                lineItem.priceAmount !== 0 &&
                                (lineItem.tags.includes("brush_heads") || lineItem.tags.includes("brushheads")) && (
                                  <PriceBrushText>{translation?.brush_head || "Bursh head"}</PriceBrushText>
                                )} */}

                              <Box>
                                {/* {lineItem.tags &&
                                  lineItem.priceAmount !== lineItem.priceDefaultAmount &&
                                  lineItem.priceDefaultAmount !== undefined &&
                                  lineItem.priceDefaultAmount !== null &&
                                  lineItem.tags.includes("uvp") && (
                                    <PriceUVPText>{translation?.tag_title_uvp || "UVP "}</PriceUVPText>
                                  )} */}
                                {/* {lineItem.isCrossedPriceVisible && ()}  */}
                                <PriceCrossedText>
                                  {lineItem.priceAmount !== lineItem.priceDefaultAmount &&
                                    lineItem.priceDefaultAmount !== undefined &&
                                    lineItem.priceDefaultAmount !== null && (
                                      <Currency
                                        price={lineItem.priceDefaultAmount * lineItem.quantity}
                                        currency={cart.currency}
                                      />
                                    )}
                                </PriceCrossedText>
                              </Box>
                              <PriceCurrentText>
                                {/* {lineItem.priceAmount === 0 ? (
                                  translation?.free_trial || "Free Trial"
                                ) : ( */}
                                <Currency price={lineItem.priceAmount * lineItem.quantity} currency={cart.currency} />
                                {/* )} */}
                              </PriceCurrentText>
                            </>
                          )}
                        {lineItem &&
                          lineItem.recurringTrialPeriodDays !== null &&
                          lineItem.recurringTrialPeriodDays !== undefined &&
                          lineItem.recurringTrialPeriodDays > 0 && (
                            <PriceLaterText>
                              {translation?.subscription_one_month_free || "Subsc. 1 month FREE"}
                            </PriceLaterText>
                          )}
                        {lineItem && cart.currency && lineItem.priceAmountAfterTrial && lineItem.quantity && (
                          <>
                            <BoxRowColumn>
                              <PriceLaterText>{translation?.then || "then"} </PriceLaterText>
                              <PriceLaterText>
                                <Currency
                                  price={lineItem.priceAmountAfterTrial * lineItem.quantity}
                                  currency={cart.currency}
                                />{" "}
                              </PriceLaterText>
                            </BoxRowColumn>
                            <BoxRowColumn>
                              <PriceLaterText>
                                {translation?.every || "every"}{" "}
                                {lineItem.recurringIntervalCount !== undefined &&
                                  lineItem.recurringIntervalCount !== null &&
                                  lineItem.recurringIntervalCount > 1 &&
                                  lineItem.recurringIntervalCount}{" "}
                                {lineItem.recurringInterval === "day" && (translation?.day || "day")}
                                {lineItem.recurringInterval === "month" && (translation?.month || "month")}
                                {lineItem.recurringInterval === "year" && (translation?.year || "year")}
                              </PriceLaterText>
                            </BoxRowColumn>
                          </>
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
              {listType === "DEV" && (
                <Box sx={{ textAlign: "right" }}>
                  <IconButton
                    style={{
                      //   backgroundColor: "#eee",
                      borderRadius: "50px",
                      fontSize: "18px",
                    }}
                    onClick={() => {
                      if (props.onDeleteLine) {
                        props.onDeleteLine(lineItem);
                      }
                    }}
                    aria-label="add"
                  >
                    <DeleteOutlineOutlinedIcon sx={{ height: 20, width: 20 }} />
                  </IconButton>
                </Box>
              )}
              <GrayLine />
            </Box>
          );
        })
      ) : (
        <>
          <div
            style={{
              textAlign: "center",
              fontWeight: 400,
              fontSize: "24px",
              fontFamily: "Gotham",
              color: theme.palette.primary.main,
            }}
          >
            {translation?.cart_has_no_items || "No Item in your cart"}
          </div>
          <GrayLine />
        </>
      )}
    </>
  );
};

export default CartList;
